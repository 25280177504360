import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { startSync, syncStatus } from "./synchronizationActions";

const synchronizationSlice = createSlice({
  name: "sync",
  initialState: {
    loading: false,
    status: "",
    error: ""
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(startSync.pending, (state, action) => {
        state.status = "startSync loading";
        state.loading = true;
      })
      .addCase(startSync.fulfilled, (state, action) => {
        state.status = "startSync succeeded";
        state.loading = true;
      })
      .addCase(startSync.rejected, (state, action) => {
        state.status = "startSync failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(syncStatus.pending, (state, action) => {
        state.status = "syncStatus loading";
        state.loading = true;
      })
      .addCase(syncStatus.fulfilled, (state, action) => {
        state.status = "syncStatus succeeded";
        state.loading = action.payload.loading;
      })
      .addCase(syncStatus.rejected, (state, action) => {
        state.status = "syncStatus failed";
        state.loading = false;
        state.error = action.error.message;
      })
  }
});

// Action creators are generated for each case reducer function
export const { } = synchronizationSlice.actions;

export default synchronizationSlice.reducer;
