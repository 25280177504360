import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    postAdd,
    getList,
    getParamsList,
    postEdit,
    patchEdit,
    postDelete,
    postFormData,
    patchImg,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
    RiCheckboxCircleLine,
    RiAlertLine,
    RiInformationLine,
} from "react-icons/ri";


export const getScheduleReports = createAsyncThunk(
    "/getScheduleReports",
    async (payload) => {
      const { params } = payload;
      try {
        const response = await getParamsList(`/reports/scheduledemail/`, params);
        if (response) {
          return { response };
        } else {
          throw new Error(response);
        }
      } catch (error) {
        Modal.error({
            icon: (
              <span className="remix-icon">
                <RiAlertLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">{error.message}</p>
              </div>
            ),
          });
        throw error.message;
      }
    }
  );


  export const startDeleteScheduleReportById = createAsyncThunk(
    "/startDeleteScheduleReportById",
    async (payload) => async (dispatch) => {
        var stateID = payload;
        try {
            const response = await postDelete("/reports/ScheduledEmail/" + stateID);
            if (response.status === 204) {
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Schedule Report has been deleted Successfully!
                            </p>
                        </div>
                    ),
                });
                return { response };
            } else {
                Modal.error({
                    icon: (
                        <span className="remix-icon">
                            <RiAlertLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Sorry!   Schedule Report has been not deleted!
                            </p>
                        </div>
                    ),
                });
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            throw error.message;
        }
    }
);