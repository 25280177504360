import { createSlice } from "@reduxjs/toolkit";
import { getPartyList,getPartyByID } from "./partyAction";

const partSlice = createSlice({
    name: "party",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,        
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        listCount: 0,
        partylist: [],
        partyParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        
    },
    reducers: {
       
        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },   
        SetPartyParams: (state, action) => {
            return {
                ...state,
                partyParams: {
                    ...state.partyParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPartyList.pending, (state, action) => {
                state.status = "getPartyList loading";
                state.loading = true;
            })
            .addCase(getPartyList.fulfilled, (state, action) => {
                console.log(action.payload.response)
                state.status = "getPartyList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.partyParams?.page_size
                );

                state.partylist = response.results;
                state.listCount = response.count;
                state.partyParams = {
                    ...state.partyParams,
                    no_of_pages: noofpages,
                };
            })
            .addCase(getPartyList.rejected, (state, action) => {
                state.status = "getPartyList failed";
                state.loading = false;
                state.error = action.error.message;
            })
            
            .addCase(getPartyByID.pending, (state, action) => {
                state.status = "getPartyByID loading";
                state.loading = true;
            })
            .addCase(getPartyByID.fulfilled, (state, action) => {
                state.status = "getPartyByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
            }
            )
            .addCase(getPartyByID.rejected, (state, action) => {
                state.status = "getPartyByID failed";
                state.loading = false;
                state.error = action.error.message;
            })
           
    }

});
export const {
    InputChangeValue,
    SetPartyParams,
    isModelVisible
} = partSlice.actions;

export default partSlice.reducer;