import { createSlice } from "@reduxjs/toolkit";
import { StockAdjustmentPlusAdd, StockAdjustmentPlusEdit, getStockAdjustmentPlusByID, getStockAdjustmentPlusList, StockAdjustmentPlusDelete,
    stockAdjustmentPlusAttachments } from "./stockAdjustmentPlusAction";

const stockAdjustmentPlusSlice = createSlice({
    name: "stockadjustmentplus",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        // autoFillItems: {},
        filterParamsData: {},
        listCount: 0,
        showCamera: false,
        stockAdjustmentPluslist: [],
        stockAdjustmentPlusItems: [],
        stockAdjustmentPlusParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        listItemCount: 0,
        pageParams: {
            start_date: "",
            end_date: "",
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
        },
        uploadImage: {
            file: {},
            imageUrl: "",
            loading: false,
        },
        stockAdjustmentPlusImages: [],
        editStockPlusItems:{}
    },
    reducers: {

        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },
        SetStockAdjustmentPlusParams: (state, action) => {
            return {
                ...state,
                stockAdjustmentPlusParams: {
                    ...state.stockAdjustmentPlusParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
        isDrawerVisible: (state, action) => {
            return {
                ...state,
                drawer: action.payload,
            };
        },
        setAutoFillItems: (state, action) => {
            return {
                ...state,
                ...action.payload,
            }
        },
        changeParams: (state, action) => {
            return {
                ...state,
                pageParams: action.payload,
            };
        },
        setStockAdjustmentItemsList: (state, action) => {
            return {
                ...state,
                stockAdjustmentPlusItems: [...state.stockAdjustmentPlusItems, action.payload]
            }
        },
        clearuploadImage: (state, action) => {
            return {
                ...state,
                uploadImage: {
                    file: {},
                    imageUrl: "",
                    loading: false,
                },
            };
        },
        setUploadImage: (state, action) => {
            return {
                ...state,
                uploadImage: {
                    file: action.payload.file,
                    imageUrl: action.payload.imageUrl,
                    loading: action.payload.loading
                },
            };
        },
        setItemsList: (state, action) => {
            return {
                ...state,
                stockAdjustmentPlusItems: [...state.stockAdjustmentPlusItems, action.payload]
            }
        },
        setShowCamera: (state, action) => {
            return {
                ...state,
                showCamera: action.payload,
            }
        },
        setStockAdjustmentPlusImages: (state, action) => {
            return {
                ...state,
                stockAdjustmentPlusImages: 
                [
                    action.payload?.primary_loading_image,
                    action.payload?.primary_unloading_image,
                    action.payload?.secondary_loading_image,
                    action.payload?.secondary_unloading_image,
                ]
            }
        },
        clearImages: (state, action) => {
			state.stockAdjustmentPlusImages = [];
		},
		removeImages: (state, action) => {
			return {
				...state,
				stockAdjustmentPlusImages: state.stockAdjustmentPlusImages?.filter(
					(e) => e.id != action.payload
				),
			};
		},
        setEditStockPlusItemsList: (state, action) => {
            return {
                ...state,
                stockAdjustmentPlusItems: state.stockAdjustmentPlusItems?.map((e) => {
                    if (e.id == action.payload.id) {
                        return {
                            ...e,
                            ...action.payload,
                        }
                    } else {
                        return e;
                    }
                })
            }
        },
        removeItems: (state, action) => {
            return {
                ...state,
                stockAdjustmentPlusItems: state.stockAdjustmentPlusItems.map((e) => {
                    return e.id == action.payload
                        ? {
                            ...e,
                            dodelete: true,
                        }
                        : {
                            ...e,
                        };
                }),
            };
        },
        setEditStockPlusItems: (state, action) => {
            return {
                ...state,
                editStockPlusItems: action.payload,
            };
        },
        removeNullValuesFromImages: (state,action)=>{
            return {
                ...state,
                stockAdjustmentPlusImages: state.stockAdjustmentPlusImages?.filter(item => item !== null)
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getStockAdjustmentPlusList.pending, (state, action) => {
                state.status = "getStockAdjustmentPlusList loading";
                state.loading = true;
            })
            .addCase(getStockAdjustmentPlusList.fulfilled, (state, action) => {
                state.status = "getStockAdjustmentPlusList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.stockAdjustmentPlusParams?.page_size
                );

                state.stockAdjustmentPluslist = response.results;
                state.listCount = response.count;
                state.stockAdjustmentPlusParams = {
                    ...state.stockAdjustmentPlusParams,
                    no_of_pages: noofpages,
                };
                state.rowdata = {};
                state.stockAdjustmentPlusImages = [];
                state.stockAdjustmentPlusItems = [];
            })
            .addCase(getStockAdjustmentPlusList.rejected, (state, action) => {
                state.status = "getStockAdjustmentPlusList failed";
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getStockAdjustmentPlusByID.pending, (state, action) => {
                state.status = "getStockAdjustmentPlusByID loading";
                state.loading = true;
            })
            .addCase(getStockAdjustmentPlusByID.fulfilled, (state, action) => {
                state.status = "getStockAdjustmentPlusByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
                state.stockAdjustmentPlusItems = state.rowdata.stockadjustmentplusitems;
                state.listItemCount = action.payload.stockadjustmentplusitems?.length;
                state.stockAdjustmentPlusImages= state.stockAdjustmentPlusImages?.filter(item => item !== null)
            }
            )
            .addCase(getStockAdjustmentPlusByID.rejected, (state, action) => {
                state.status = "getStockAdjustmentPlusByID failed";
                state.loading = false;
                state.error = action.error.message;

            })
            .addCase(StockAdjustmentPlusAdd.pending, (state, action) => {
                state.status = "StockAdjustmentPlusAdd loading";
                state.loading = true;
            })
            .addCase(StockAdjustmentPlusAdd.fulfilled, (state, action) => {
                state.status = "StockAdjustmentPlusAdd succeeded";
                state.loading = false
                state.model = action.payload.modal;
                state.message = action.payload.response;
                state.rowdata = {};
                state.stockAdjustmentPlusItems = [];
            }
            )
            .addCase(StockAdjustmentPlusAdd.rejected, (state, action) => {
                state.status = "StockAdjustmentPlusAdd failed";
                state.loading = false;
                state.error = action.error.message;
                console.log("action.error.message", action.error.message);
            })
            .addCase(StockAdjustmentPlusEdit.pending, (state, action) => {
                state.status = "StockAdjustmentPlusEdit loading";
                state.loading = true;
            })
            .addCase(StockAdjustmentPlusEdit.fulfilled, (state, action) => {
                console.log(action.payload.response)
                state.status = "StockAdjustmentPlusEdit succeeded";
                state.loading = false;
                state.active = action.payload.active;
                state.stockAdjustmentPlusItems = [];
            })
            .addCase(StockAdjustmentPlusEdit.rejected, (state, action) => {
                state.status = "StockAdjustmentPlusEdit failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(StockAdjustmentPlusDelete.pending, (state, action) => {
                state.status = "StockAdjustmentPlusDelete loading";
                state.loading = true;
            })
            .addCase(StockAdjustmentPlusDelete.fulfilled, (state, action) => {
                state.status = "StockAdjustmentPlusDelete succeeded";
                state.loading = false
            }
            )
            .addCase(StockAdjustmentPlusDelete.rejected, (state, action) => {
                state.status = "StockAdjustmentPlusDelete failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(stockAdjustmentPlusAttachments.pending, (state, action) => {
				state.status = "loading";
				state.loading = true;
			})
			.addCase(stockAdjustmentPlusAttachments.fulfilled, (state, action) => {
				const { response } = action.payload;
				state.status = "succeeded";
				state.loading = false;
				state.stockAdjustmentPlusImages = [...state.stockAdjustmentPlusImages, response];
			})
			.addCase(stockAdjustmentPlusAttachments.rejected, (state, action) => {
				state.status = "failed";
				state.loading = false;
				state.error = action.error.message;
			})
    }

});
export const {
    InputChangeValue,
    SetStockAdjustmentPlusParams,
    isModelVisible,
    isDrawerVisible,
    setAutoFillItems,
    changeParams,
    setStockAdjustmentItemsList,
    clearuploadImage,
    setUploadImage,
    setEditStockPlusItems,
    setEditStockPlusItemsList,
    removeItems,
    removeImages,
    setShowCamera,
    clearImages,
    setStockAdjustmentPlusImages,
    removeNullValuesFromImages
} = stockAdjustmentPlusSlice.actions;

export default stockAdjustmentPlusSlice.reducer;