import React from "react";

import { Card, Col, Row, Button, Input } from "antd";
import { Link, useHistory } from "react-router-dom";

import { RiAddCircleFill, RiArrowLeftCircleFill } from "react-icons/ri";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

export default function MasterPageTitle(props) {
  let history = useHistory()
  const userAccessList = useSelector((state) => state.system.userAccessList);
  const { pageTitle, pageText, addModelTile, goBack, setIsModalVisible } =
    props;

  return (
    <Col span={24}>
      <Card className="hp-m-0">
        <Row>
          <Col span={18}>
            {goBack && (
              <Link
                to="#"
                onClick={() => history.push("/Masters")}
                className="action-icon text-primary  hp-mr-12 hp-d-inline-block"
              >
                <RiArrowLeftCircleFill className="remix-icon" size={24} />
              </Link>
            )}
            <Row className="hp-d-inline-block">
              <Col span={24}>
                {pageTitle && <h2 className="hp-m-0">{pageTitle}</h2>}
              </Col>

              <Col span={24}>
                {pageText && <p className="hp-mb-0 hp-p1-body">{pageText}</p>}
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            {addModelTile && ( //userAccessList?.indexOf(`${props.prefix}.add_${props.postfix}`) !== -1 && 
              <Button
                className="hp-float-right"
                type="primary"
                onClick={() => setIsModalVisible(true)}
                block
                icon={<RiAddCircleFill className="remix-icon" size={22} />}
              >
                {addModelTile}
              </Button>
            )}
          </Col>


        </Row>
      </Card>
    </Col >
  );
}
