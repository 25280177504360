import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { getScheduleReports, startDeleteScheduleReportById } from "./scheduleReportActions";

const scheduleReportSlice = createSlice({
  name: "scheduleReport",
  initialState: {
    listCount: 0,
    model: false,

    scheduleReports: {
      scheduleCount: 0,
      getScheduleReportsData: {},
      getScheduleReportsSuccessData: [],
    },
  },
  reducers: {
   
  },

  extraReducers: (builder) => {
    builder
      .addCase(getScheduleReports.pending, (state, action) => {
        state.loading = true;
        state.status = "getScheduleReports pending";
      })
      
      .addCase(getScheduleReports.fulfilled, (state, action) => {
        state.status = "getScheduleReports succeeded";
        state.loading = false;
        state.scheduleReports = {
          ...state.scheduleReports,
          scheduleCount: action.payload.count,
          getScheduleReportsSuccessData: action.payload.results,
        }
      })
      .addCase(getScheduleReports.rejected, (state, action) => {
        state.status = "getScheduleReports failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(startDeleteScheduleReportById.pending, (state, action) => {
        state.loading = true;
        state.status = "startDeleteScheduleReportById pending";
      })
      .addCase(startDeleteScheduleReportById.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "startDeleteScheduleReportById succeeded";
      })
      .addCase(startDeleteScheduleReportById.rejected, (state, action) => {
        state.status = "getScheduleReports failed";
        state.loading = false;
        state.error = action.error.message
      })
  }
});

// Action creators are generated for each case reducer function
export const {
  InputChangeValue,
} = scheduleReportSlice.actions;

export default scheduleReportSlice.reducer;
