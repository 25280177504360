import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { Row, Col } from "antd";

import PageTitle from "../../layout/components/content/page-title";
import { sidebarEnable } from "../../store/customise/customise";

export default function Home() {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isMobile) {
      dispatch(sidebarEnable(true));
    }
    return () => {
      dispatch(sidebarEnable(false));
    };
  }, []);

  return (
    <Row
      gutter={[24, 24]}
      className="hp-booking-tab"
      style={{ marginBottom: "20px" }}
    >
      <PageTitle pageTitle="Dashboard" goBack={false} />
      <Col>DASHBOARD</Col>
    </Row>
  );
}

