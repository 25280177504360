import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { getNotificationList, readNotifition } from "./notificationAction";

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notificationList: [],
    drawer: false,
    loading: false,
    active: false,
    listCount: 0,
    notificationParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {

    updateNotification: (state, action) => {
      return {
        ...state,
        loading: true,
        notificationList: [
          {
            subject: action.payload.message,
            type: action.payload.type,
            created_on: moment(
              action.payload.ref_id,
              "YYYY-MM-DD HH:mm:ss"
            ).format("DD-MM-YYYY hh:mm:ss a"),
          },
          ...state.notificationList,
        ],
        listCount: state.listCount + 1,
      };
    },

    SetNotificationParams: (state, action) => {
      return {
        ...state,
        notificationParams: action.payload,
      };
    },
    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
    notificationActive: (state, action) => {
      return {
        ...state,
        loading: true,
        active: action.payload,
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationList.pending, (state, action) => {
        state.status = "getNotificationList loading";
        if (action.payload?.page == 1) {
          return {
            ...state,
            loading: true,
            listCount: 0,
            notificationList: [],
            notificationParams: {
              ...state.notificationParams,
              page_size: 10,
              page: 1,
              search: "",
            },
          };
        } else {
          return {
            ...state,
            loading: true,
          };
        }
      })
      .addCase(getNotificationList.fulfilled, (state, action) => {
        console.log(action.payload.response)
        state.status = "getNotificationList succeeded";
        var noofpages = Math.ceil(
          action.payload.response.count / state.notificationParams.page_size
        );
        return {
          ...state,
          listCount: action.payload.response.count,
          notificationList: [
            ...state.notificationList,
            ...action.payload.response.results,
          ],
          loading: false,
          notificationParams: {
            ...state.notificationParams,
            no_of_pages: noofpages,
          },
        };
      })
      .addCase(getNotificationList.rejected, (state, action) => {
        state.status = "getNotificationList failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(readNotifition.pending, (state, action) => {
        state.status = "readNotifition loading";
        state.loading = true;
      })
      .addCase(readNotifition.fulfilled, (state, action) => {
        console.log(action.payload.response)
        state.status = "readNotifition succeeded";
        state.loading = false;
        state.active = action.payload.active;
      })
      .addCase(readNotifition.rejected, (state, action) => {
        state.status = "readNotifition failed";
        state.loading = false;
        state.error = action.error.message;
      })
  }
});
export const {
  SetNotificationParams,
  isDrawerVisible,
  notificationActive,
  updateNotification,
} = notificationSlice.actions;

export default notificationSlice.reducer;
