import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Dropdown, Col, Avatar, Divider, Row, Typography, Modal } from "antd";
import { Calendar, Game, People, Setting } from "react-iconly";
import { FaPowerOff } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RiInformationLine } from "react-icons/ri";
import { v4 as uuidv4 } from "uuid";
import { browserName } from "react-device-detect";
// Redux
import { useDispatch } from "react-redux";

import avatarImg from "../../../assets/images/user.png";
import { userMenuActive } from "../../../store/system/systemSlice";
// import {
//   userMenuActive
// } from "../../../store/system/systemSlice";
// actions
// import { logoutUser } from "../../../store/login/loginSlice";

function HeaderUser(props) {
  const { Text } = Typography;
  const dispatch = useDispatch();
  const system = useSelector((state) => state.system);

  const uuid = uuidv4();
  const { confirm } = Modal;
  const history = useHistory();

  function showConfirm() {
    // props.userMenuActive(false);
    dispatch(userMenuActive(false));
    confirm({
      title: (
        <h5 className="hp-mb-0 hp-font-weight-500">
          Are you sure want Logout ?
        </h5>
      ),
      icon: (
        <span className="remix-icon">
          <RiInformationLine />
        </span>
      ),

      onOk() {
        // dispatch(
        //   logoutUser({
        //     data: {
        //       device_uuid: uuid,
        //       device_name: browserName,
        //       device_type: 3, // web
        //       refresh: localStorage.getItem("refresh_token"),
        //     },
        //     history,
        //   })
        // );

        localStorage.clear();
        history.push("/login");
      },
      onCancel() { },
    });
  }

  const menu = (
    <div
      className="hp-border-radius hp-border-1 hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-p-24 hp-mt-12"
      style={{ width: 260 }}>
      <span className="hp-d-block h5 hp-text-color-black-100 hp-text-color-dark-0 hp-mb-8">
        {localStorage.getItem("User_full_name") || localStorage.getItem("User_full_name") !== "undefined" ? localStorage.getItem("User_full_name") : null}

        {`( ${localStorage.getItem("username")} )`}
      </span>

      <Link
        to="/pages/userProfile/personel-information"
        className="hp-p1-body hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-hover-text-color-primary-3 hp-font-weight-700"
        onClick={() => {
          dispatch(userMenuActive(false));
        }}>
        View Profile
      </Link>

      <Divider className="hp-mt-16 hp-mb-6" />

      <Row>
        {/* {system.userAccessList?.indexOf("System.view_masters") !== -1 && ( */}
        <Col span={24}>
          <Link
            to="/Masters"
            className="hp-d-flex-center hp-p1-body hp-py-8 hp-px-10 hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius
              hp-font-weight-700 hp-text-color-primary-3"
            style={{ marginLeft: -10, marginRight: -10 }}
            onClick={() => {
              dispatch(userMenuActive(false));
            }}>
            <People set="curved" size={16} />

            <span className="hp-ml-8">Masters</span>
          </Link>
          <Divider className="hp-mb-16 hp-mt-6" />
        </Col>
        {/* )} */}

        {system.userAccessList?.indexOf("System.view_setting") !== -1 && (
          <Col span={24}>
            <Link
              to="/pages/settings/"
              className="hp-d-flex-center hp-p1-body hp-py-8 hp-px-10 hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius
              hp-font-weight-700 hp-text-color-primary-3"
              style={{ marginTop: -7, marginLeft: -10, marginRight: -10 }}
              onClick={() => {
                dispatch(userMenuActive(false));
              }}>
              <Setting set="curved" size={16} />

              <span className="hp-ml-8">Settings</span>
            </Link>
            <Divider className="hp-mb-16 hp-mt-6" />
          </Col>
        )}

        {/* <Col span={24}>
          <Link
            to="/"
            className="hp-d-flex-center hp-p1-body hp-py-8 hp-px-10 hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius"
            style={{ marginTop: -7, marginLeft: -10, marginRight: -10 }}
          >
            <Game set="curved" size={16} />

            <span className="hp-ml-8">Help Desk</span>
          </Link>
        </Col> */}
      </Row>

      <Link
        to="#"
        // onClick={() => {
        //   dispatch(
        //     logoutUser({
        //       data: {
        //         device_uuid: uuid,
        //         device_name: browserName,
        //         device_type: 3, // web
        //         refresh: localStorage.getItem("refresh_token"),
        //       },
        //       history,
        //     })
        //   );
        // }}
        onClick={showConfirm}
        // onClick={() => {
        //   showConfirm
        //   props.userMenuActive(false);
        // }}
        className="hp-d-flex-center hp-p1-body hp-py-8 hp-px-10 hp-d-block hp-transition  hp-border-radius"
        style={{ marginTop: -7, marginLeft: -10, marginRight: -10 }}>
        <FaPowerOff set="curved" size={16} color="red" />

        <Text className="hp-ml-8" type="danger">
          Logout
        </Text>
      </Link>
    </div>
  );

  return (
    <Col>
      <Dropdown
        dropdownRender={() => menu}
        onOpenChange={(value) => dispatch(userMenuActive(value))}
        open={system.menuItemStatus}
        trigger={["click"]}>
        <Avatar src={avatarImg} size={40} className="hp-cursor-pointer" />
      </Dropdown>
    </Col>
  );
}

export default HeaderUser;
