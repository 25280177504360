import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    getList,
    getParamsList,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
    RiAlertLine,
} from "react-icons/ri";
import Swal from "sweetalert2";


const delay = (ms) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
async function checkStatus(payload) {
    const { dispatch } = payload;
    let timeout;
    timeout = await delay(3000);
    dispatch(syncStatus(payload));
}
export const startSync = createAsyncThunk(
    "/startSync",
    async (payload, { dispatch }) => {
        console.log(payload)
        const { label, name, requestUrl, statusUrl } = payload;
        try {
            const response = await getList(requestUrl);
            if (response.data) {
                dispatch(syncStatus(payload))
                return response.data
            } else {
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            return error.message;
        }
    }
);

export const syncStatus = createAsyncThunk(
    "/syncStatus",
    async (payload, { dispatch }) => {
        const { label, name, requestUrl, statusUrl } = payload;
        try {
            const response = await getList(statusUrl);
            if (!response.data.status) {
                Swal.fire({
                    title: "Success",
                    text: `${label} Synchronization Completed`,
                    icon: "success",
                    confirmButtonColor: "#3085d6",
                    // confirmButtonText: "Cool",
                });
                return { loading: false };
            } else {
                checkStatus({ ...payload, dispatch });
                return { loading: true }
            }
        } catch (error) {
            Swal.fire({
                title: "Error",
                text: "Error while synchronizing data",
                icon: "error",
                confirmButtonColor: "#3085d6",
                // confirmButtonText: "Cool",
            });
            return error.message;
        }
    }
);
// export const startAmcuSync = createAsyncThunk(
//     "/startAmcuSync",
//     async () => {
//         try {
//             const response = await getList("/thirdparty/amcu_data_collection/");
//             if (response.data) {
//                 return response.data
//             } else {
//                 throw new Error(response);
//             }
//         } catch (error) {
//             Modal.error({
//                 icon: (
//                     <span className="remix-icon">
//                         <RiAlertLine />
//                     </span>
//                 ),

//                 title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
//                 content: (
//                     <div>
//                         <p className="hp-p1-body hp-text-color-black-80">
//                             Sorry! Getting from server side issue!
//                         </p>
//                     </div>
//                 ),
//             });
//             return error.message;
//         }
//     }
// );

// export const amcuSyncStatus = createAsyncThunk(
//     "/amcuSyncStatus",
//     async (payload, { dispatch }) => {
//         try {
//             const response = await getParamsList(
//                 `/thirdparty/amcu_data_collection/status/`,
//                 payload
//             );
//             console.log(response);
//             if (!response.status) {
//                 Swal.fire({
//                     title: "Success",
//                     text: "Process Completed",
//                     icon: "success",
//                     confirmButtonColor: "#3085d6",
//                     // confirmButtonText: "Cool",
//                 });
//                 return response;
//             } else {
//                 checkStatus({ dispatch, thunk: "syncStatus", });
//             }
//         } catch (error) {
//             Swal.fire({
//                 title: "Error",
//                 text: "Error while processing",
//                 icon: "error",
//                 confirmButtonColor: "#3085d6",
//                 // confirmButtonText: "Cool",
//             });
//             return error.message;
//         }
//     }
// );
