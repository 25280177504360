import { createSlice } from "@reduxjs/toolkit";
import { getReportList, startPostScheduleReport } from "./reportsAction";

const reportsSlice = createSlice({
  name: "reports",
  initialState: {
    show: false,
    reportsItemsList: [],
    reportDataList: [],
    reportsDataCount: 0,
    modelName: "",
    reportParamsData: { file_format: 5 },
    columnsList: [],
    reportView: false,
    error: '',
    status: '',
    loading: false,
    scheduleLoading: false,
    scheduleReports: {
      scheduleCount: 0,
      getScheduleReportsData: {},
      getScheduleReportsSuccessData: [],
    },
    pageParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
  },
  reducers: {
    clearData: (state) => {
      return {
        ...state,
        reportsItemsList: [],
        reportDataList: [],
        reportsDataCount: 0,
        modelName: "",
        reportParamsData: { file_format: 5 },
        columnsList: [],
        reportView: false,
        error: '',
        status: '',
        loading: false,
        pageParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        reportParamsData: {
          ...state.reportParamsData,
          [action.payload.key]: action.payload.value,
        },
      }
    },
    changeParams: (state, action) => {
      return {
        ...state,
        pageParams: action.payload,
      };
    },
    setReportParamsData: (state,action)=>{
      return {
        ...state,
        reportParamsData : action.payload
      }
    }
  },

  extraReducers: (builder) => {
    builder.
      addCase(getReportList.pending, (state, action) => {
        state.status = "getReportList loading";
        state.loading = true;
      }).addCase(getReportList.fulfilled, (state, action) => {
        // state.status = "getReportList succeeded";
        var columns = [];
        if (action.payload.response?.results.length > 0) {
          columns = Object.keys(action.payload.response?.results[0]);
        }
        return {
          ...state,
          status: "getReportList succeeded",
          reportDataList: action.payload.response?.results,
          reportsDataCount: action.payload.response?.count,
          columnsList: columns,
          loading: false,
          reportView: true,
        };
      }).addCase(getReportList.rejected, (state, action) => {
        state.status = "getReportList failed";
        state.loading = false;
        state.error = action.error.message
      })
      .addCase(startPostScheduleReport.pending, (state, action) => {
        state.status = "startPostScheduleReport loading";
        state.loading = true;
      }).addCase(startPostScheduleReport.fulfilled, (state, action) => {
        state.status = "startPostScheduleReport succeeded";
        state.loading = false;
        state.scheduleReports = {
          ...state.scheduleReports,
          getScheduleReportsSuccessData: [
            action.payload,
            ...state.scheduleReports.getScheduleReportsSuccessData,
          ],
        };
      }).addCase(startPostScheduleReport.rejected, (state, action) => {
        state.status = "startPostScheduleReport failed";
        state.loading = false;
        state.error = action.error.message
      })
  }
});

// Action creators are generated for each case reducer function
export const {
  clearData,
  InputChangeValue,
  changeParams,
  setReportParamsData
} = reportsSlice.actions;

export default reportsSlice.reducer;
