import { createSlice } from "@reduxjs/toolkit";
import {
  getBackUpDataBaseList,
  getDataBaseBackUP,
  DataBaseVerifyPassword
} from "./backupDatabaseAction";

export const backupDatabaseSlice = createSlice({
  name: "backupDatabase",
  initialState: {
    listCount: 0,
    backUpDataBaselist: [],
    locationManagerData: {},
    backupData: {},
    model: false,
    passwordModel: false,
    dataBaseId: 0,
    backUpDataBaseParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    loadingStatus: (state, action) => {
      return { ...state, loading: action.payload };
    },
    setBackUpDataBaseParams: (state, action) => {
      return {
        ...state,
        backUpDataBaseParams: action.payload,
      };
    },
    isPasswordModel: (state, action) => {
      if (!action.payload.model) {
        return {
          ...state,
          passwordModel: action.payload.model,
          backupData: {},
          dataBaseId: 0,
          verifyPwdloading: false
        };
      } else {
        return {
          ...state,
          passwordModel: action.payload.model,
          dataBaseId: action.payload.Id
       
        };
      }
    },
    InputChangeValue: (state, action) => {
      return{
        ...state,
        backupData: {
          ...state.backupData,
          [action.payload.key]: action.payload.value,
        },
      }
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload, verifyPwdloading: false };
    },
  },

  extraReducers: (builder) => {
    builder 
      .addCase(getBackUpDataBaseList.pending, (state, action) => {
        state.status = "getBackUpDataBaseList loading";
        state.loading = true;
      })
      .addCase(getBackUpDataBaseList.fulfilled, (state, action) => {
        state.status = "getBackUpDataBaseList succeeded";
        state.loading = false;
        var noofpages = Math.ceil(
          action.payload.count / state.backUpDataBaseParams.page_size
        );
        state.count = action.payload.count;
        state.backUpDataBaselist = action.payload.results;
        state.backUpDataBaseParams = {
          ...state.backUpDataBaseParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getBackUpDataBaseList.rejected, (state, action) => {
        state.status = "getBackUpDataBaseList failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getDataBaseBackUP.pending, (state, action) => {
        state.status = "getDataBaseBackUP loading";
        state.loading = true;
      })
      .addCase(getDataBaseBackUP.fulfilled, (state, action) => {
        state.status = "getDataBaseBackUP succeeded";
        state.loading = false
        // state.model = true;
        // state.rowdata = {...action.payload};
      })
      .addCase(getDataBaseBackUP.rejected, (state, action) => {
        state.status = "getDataBaseBackUP failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(DataBaseVerifyPassword.pending, (state, action) => {
        state.status = "DataBaseVerifyPassword loading";
        state.verifyPwdloading = true;
      })
      .addCase(DataBaseVerifyPassword.fulfilled, (state, action) => {
        state.status = "DataBaseVerifyPassword succeeded";
        state.verifyPwdloading = false;
      })
      .addCase(DataBaseVerifyPassword.rejected, (state, action) => {
        state.status = "DataBaseVerifyPassword failed";
        state.verifyPwdloading = false;
        state.error = action.error.message;
      })
  }
});

// Action creators are generated for each case reducer function
export const {
  setBackUpDataBaseParams,
  loadingStatus,
  isPasswordModel,
  verifyPassword,
  InputChangeValue,
  apiError,
} = backupDatabaseSlice.actions;

export default backupDatabaseSlice.reducer;
