import React, { useEffect } from "react";
import logo from "../../assets/images/logo/PAPPL_logo.png";
import { Card, Row, Col } from "antd";
import { useHistory } from "react-router-dom";

export default function Spalsh(props) {
  const history = useHistory();


  useEffect(() => {
    // setTimeout(() => {
    if (localStorage.getItem("access_token")) {
      // navigate("/dashboard", { replace: true });
      history.push("/dashboard");
    } else {
      // navigate("/login", { replace: true });
      history.push("/login");
    }
    // }, 1000);
    // console.log("localStorage.getItem", localStorage.getItem("access_token"));
    // if(localStorage.getItem("access_token")){
    //     navigate("/dashboard");
    //   }
    //   else{
    //       navigate("/login");
    //   }
  }, []);

  return (
    <Row className=" hp-text-center">
      <Col className="hp-error-content hp-py-32" span={24}>
        <Row className="hp-h-100" align="middle" justify="center">
          <Col>
            <img
              className="hp-position-relative "
              src={logo}
              style={{
                height: 150,
                width: 500,
              }}
              alt="logo"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
