import { createAsyncThunk } from "@reduxjs/toolkit";
import { Modal } from "antd";
import {
	RiCheckboxCircleLine,
	RiAlertLine,
	RiInformationLine,
} from "react-icons/ri";

import { getList, getParamsList } from "../../helpers/Helper";

const seralizeParams = (payload) => {
	var params = {};
	for (const k in payload) {
		if (Object.hasOwnProperty.call(payload, k)) {
			if (k === "type") {
				params.type = payload.type.join(",");
			} else {
				if (payload[k] !== "" && k !== "no_of_pages") {
					params[k] = payload[k];
				}
			}
		}
	}
	return params;
};

export const getUserPermissions = createAsyncThunk(
	"/getUserPermissions",
	async (payload) => {
		try {
			const response = await getList("/users/userpermissions/", {});
			console.log(response);
			if (response.data) {
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			throw error.message;
		}
	}
);
export const getMiniStates = createAsyncThunk(
	"/getMiniStates",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"/masters/states/mini/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniCity = createAsyncThunk("/getMiniCity", async (payload) => {
	var params = seralizeParams(payload);
	try {
		const response = await getParamsList("/masters/citys/mini/", params);

		if (response) {
			return { response, params };
		} else {
			throw new Error(response);
		}
	} catch (error) {
		return error.message;
	}
});
export const getMiniArea = createAsyncThunk("/getMiniArea", async (payload) => {
	var params = seralizeParams(payload);

	try {
		const response = await getParamsList("/masters/area/mini/", params);
		if (response) {
			return { response, params };
		} else {
			throw new Error(response);
		}
	} catch (error) {
		return error.message;
	}
});
export const getMiniLocation = createAsyncThunk(
	"/getMiniLocation",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"/masters/location/mini/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniZone = createAsyncThunk("/getMiniZone", async (payload) => {
	var params = seralizeParams(payload);
	try {
		const response = await getParamsList("/masters/zone/mini/", params);
		if (response) {
			return { response, params };
		} else {
			throw new Error(response);
		}
	} catch (error) {
		return error.message;
	}
});

export const getMiniUsers = createAsyncThunk(
	"/getMiniUsers",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList("/users/mini/users/", params);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniRetailers = createAsyncThunk(
	"/getMiniRetailers",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"/users/retailers/minilist/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniSuperStockist = createAsyncThunk(
	"/getMiniSuperStockist",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"/users/superstockist/minilist/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniSalesAgent = createAsyncThunk(
	"/getMiniSalesAgent",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"/users/saleagent/minilist/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniDistributors = createAsyncThunk(
	"/getMiniDistributors",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"/users/distributors/minilist/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniProducts = createAsyncThunk(
	"/getMiniProducts",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"/masters/product/mini/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniSizes = createAsyncThunk(
	"/getMiniSizes",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList("/masters/unit/mini/", params);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniProductsType = createAsyncThunk(
	"/getMiniProductsType	",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"/masters/producttype/mini/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniOrdersList = createAsyncThunk(
	"/getMiniOrdersList",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"/orders/orderminilist/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniCustomersList = createAsyncThunk(
	"/getMiniCustomersList",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"/users/customers/minilist/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniReasonsList = createAsyncThunk(
	"/getMiniReasonsList",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"/b2c/admin/reasonforwallet/mini/",
				{ ...params, type: params.reason_type }
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniDeliveryInstructionsList = createAsyncThunk(
	"/getMiniDeliveryInstructionsList",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"/masters/deliveryinstructions/mini/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniDeliveryPersonsList = createAsyncThunk(
	"/getMiniDeliveryPersonsList",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"/users/deliveryperson/minilist/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniCashCollectionTimeslots = createAsyncThunk(
	"/getMiniCashCollectionTimeslots",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"/masters/admin/cashcollectiontimeslots/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniHub = createAsyncThunk("/getMiniHub", async (payload) => {
	var params = seralizeParams(payload);
	try {
		const response = await getParamsList("/masters/hubs/mini/", params);
		if (response) {
			return { response, params };
		} else {
			throw new Error(response);
		}
	} catch (error) {
		return error.message;
	}
});
export const getMiniRetailerIndents = createAsyncThunk(
	"/getMiniRetailerIndents",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"/orders/retailerindents/minilist/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniRetailerReceipts = createAsyncThunk(
	"/getMiniRetailerReceipts",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"/orders/receipts/minilist/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniDistributorIndents = createAsyncThunk(
	"/getMiniDistributorIndents",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"/orders/distributorindents/minilist/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniDistributorReceipt = createAsyncThunk(
	"/getMiniDistributorReceipt",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"/orders/distributorreceipts/minilist/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

export const getMiniJattu = createAsyncThunk(
	"/getMiniJattu",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"masters/jattu/mini/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniJattuActivity = createAsyncThunk(
	"/getMiniJattuActivity",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"masters/jattuactivity/mini/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

export const getMiniVehicleInward = createAsyncThunk(
	"/getMiniVehicleInward",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"towards/vehicleinward/mini/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMinicompany = createAsyncThunk(
	"/getMinicompany",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"masters/company/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniWarehouse = createAsyncThunk(
	"/getMiniWarehouse",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"masters/warehouse/mini/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniLot = createAsyncThunk(
	"/getMiniLot",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"masters/lot/mini/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniItem = createAsyncThunk(
	"/getMiniItem",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"masters/item/mini/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniTrucksheet = createAsyncThunk(
	"/getMiniTrucksheet",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"masters/truckSheet/mini/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getMiniDeliveryorder = createAsyncThunk(
	"/getMiniDeliveryorder",
	async (payload) => {
		var params = seralizeParams(payload);
		try {
			const response = await getParamsList(
				"masters/deliveryorder/mini/",
				params
			);
			if (response) {
				return { response, params };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);