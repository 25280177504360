import { createSlice } from "@reduxjs/toolkit";
import { } from "./dashboardAction";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    error: "",
    status: "",
    loading: false,
  },
  reducers: {

  },

  extraReducers: (builder) => {

  }
});

// Action creators are generated for each case reducer function
export const {
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
