import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	postAdd,
	getList,
	getParamsList,
	postEdit,
	patchEdit,
	postDelete,
	postFormData,
	patchImg,
} from "../../../helpers/Helper";

import { Modal } from "antd";
import {
	RiCheckboxCircleLine,
	RiAlertLine,
	RiInformationLine,
} from "react-icons/ri";

export const getManageCompany = createAsyncThunk(
	"/getManageCompany",
	async (payload) => {
		try {
			const response = await getList("/system/dynamicsettings/", {});
			if (response.data) {
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			Modal.error({
				icon: (
					<span className="remix-icon">
						<RiAlertLine />
					</span>
				),

				title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
				content: (
					<div>
						<p className="hp-p1-body hp-text-color-black-80">
							Sorry! Getting from server side issue!
						</p>
					</div>
				),
			});
			return error.message;
		}
	}
);
export const manageCompanyAdd = createAsyncThunk(
  "/manageCompanyAdd",
  async (payload) => {
    const { obj, onSuccess, onFailure } = payload;
    try {
      const response = await postAdd("/system/dynamicsettings/", { ...obj });
      if (response.status >= 200 && response.status < 300) {
        onSuccess(response.data);
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      if (error.response.status === 400) {
        onFailure(error.response.data.error?.[0]);
        throw error.response;
      } else {
        onFailure("In-correct Order Request Details Details");
      }
    }
  }
);
export const getCompanyLogoUpload = createAsyncThunk("/getCompanyLogoUpload",
	async (payload) => {
		try {
			const response = await patchImg(
				"/system/dynamicsettings/" + payload.imageType,
				{
					value: payload.file,
				}
			);
			return {
				response,
			};
		} catch (error) {
			return error.message;
		}
	}
);
