import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postAdd,
  getParamsList,
  addParams,
  postEdit,
  postDelete,
  getList,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

export const getProfileList = createAsyncThunk(
  "/getProfileList",
  async (payload) => {
    var params = addParams(payload);
    try {
      const response = await getParamsList("/users/groups/", params);
      if (response) {
        return { response };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);
export const getProfilebyID = createAsyncThunk(
  "/getProfilebyID",
  async (payload) => {
    const { id } = payload;
    try {
      const response = await getList("/users/groups/" + id, {});
      if (response) {
        return { response: response.data };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);
export const submitProfile = createAsyncThunk(
  "/submitProfile",
  async (payload) => {
    console.log(payload);
    try {
      if (payload.profileObj.id == 0) {
        const response = await postAdd("/users/groups/", payload.profileObj);
        if (response) {
          Modal.success({
            icon: (
              <span className="remix-icon">
                <RiCheckboxCircleLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Group Added Successfully.!
                </p>
              </div>
            ),
          });
          payload.clearDataFn();
          payload.history.push("/pages/settings/manage-profile");
        } else {
          Modal.error({
            icon: (
              <span className="remix-icon">
                <RiAlertLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Sorry! Unable to add Group. Please try again!
                </p>
              </div>
            ),
          });
          throw new Error(response);
        }
      } else {
        const response2 = await postEdit(
          "/users/groups/" + payload.profileObj.id,
          payload.profileObj
        );
        if (response2.status == 200) {
          Modal.success({
            icon: (
              <span className="remix-icon">
                <RiCheckboxCircleLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Group Edited Successfully.!
                </p>
              </div>
            ),
          });
          payload.history.push("/pages/settings/manage-profile");
        } else {
          Modal.error({
            icon: (
              <span className="remix-icon">
                <RiAlertLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Sorry! Unable to edit Group. Please try again!
                </p>
              </div>
            ),
          });
        }
      }
    } catch (error) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to edit Group. Please try again!
            </p>
          </div>
        ),
      });
      throw error.message;
    }
  }
);
export const getPermissionsList = createAsyncThunk(
  "/getPermissionsList",
  async (payload) => {
    // var params = addParams(payload);
    const { id } = payload;
    try {
      const response = await getParamsList("/users/apps/permissions/", {});
      if (response) {
        return { response, id };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);
export const getFilteredProfileList = createAsyncThunk(
  "/getFilteredProfileList",
  async (payload) => {
    var params = addParams(payload);
    try {
      const response = await getParamsList("/users/groups/admin/list", params);
      if (response) {
        return { response };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);
export const getManualRegProfileList = createAsyncThunk(
  "/getManualRegProfileList",
  async (payload) => {
    var params = addParams(payload);
    try {
      const response = await getParamsList(
        "/users/mobilegroups/admin/list",
        params
      );
      if (response) {
        return { response };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);
export const profileDelete = createAsyncThunk(
  "/profileDelete",
  async (payload) => {
    const { id, onSuccess, onFailure } = payload;
    try {
      const response = await postDelete("/users/groups/" + id);
      if (response.status === 204) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Group has been deleted!
              </p>
            </div>
          ),
        });
        onSuccess(response);
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Your Group has been not deleted!
              </p>
            </div>
          ),
        });
      }
      throw new Error(response);
    } catch (error) {
      onFailure(error.message);
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });

      return error.message;
    }
  }
);
