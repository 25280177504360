import { createAsyncThunk } from "@reduxjs/toolkit";
import { message, Modal } from "antd";
import { RiAlertLine, RiCheckboxCircleLine } from "react-icons/ri";
import {
    getParamsList,
    postEdit
} from "../../helpers/Helper";


const seralizeParams = (payload) => {
    var params = {};
    for (const k in payload) {
        if (Object.hasOwnProperty.call(payload, k)) {
            if (k === "type") {
                params.type = payload.type.join(",");
            } else {
                if (payload[k] !== "" && k !== "no_of_pages") {
                    params[k] = payload[k];
                }
            }
        }
    }
    return params;
};

export const getNotificationList = createAsyncThunk(
    "/getNotificationList",
    async (payload) => {
        const params = seralizeParams(payload)
        try {
            const response = await getParamsList("/system/Notification/", params);
            return { response };
        } catch (err) {
            throw err;
        }
    }
);
export const readNotifition = createAsyncThunk(
    "/readNotifition",
    async (payload) => {
        try {
            const response = await postEdit("/system/Notification/Clear/" + payload, {});
            if (response.status === 200) {

                return { response, active: false };
            } else {
                throw new Error("Sorry! Unable to update Notification. Please try again!")
            }
        } catch (err) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            {err.message}
                        </p>
                    </div>
                ),
            });
            throw err;
        }
    }
);