import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../helpers/AxiosHelper";
import {
  postAdd,
  getParamsList,
  addParams,
  postEdit,
  postDelete,
} from "../../../helpers/Helper";
import { message, Modal } from "antd";
import { RiAlertLine, RiCheckboxCircleLine } from "react-icons/ri";

const seralizeParams = (payload) => {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  return params;
};

export const getMangeUsersData = createAsyncThunk(
  "/getMangeUsersData",
  async (payload) => {
    const { params } = payload;
    try {
      const response = await getParamsList(`/users/list/`, params);
      if (response) {
        return { response };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);
export const getMangeUsersDataById = createAsyncThunk(
  "/getMangeUsersDataById",
  async (payload, { dispatch }) => {
    const { id, onSuccess, onFailure } = payload;
    try {
      const response = await getParamsList(`/users/${id}`, {});
      if (response) {
        onSuccess(response);
        return { response };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      onFailure(error.message);
      throw error.message;
    }
  }
);

export const postMangeUsersData = createAsyncThunk(
  "/postMangeUsersData",
  async (payload, { dispatch }) => {
    const { data, handleAdd, userDetailsForm, clearData, history ,onSuccess,onFailure} = payload;
    try {
      const response = await postAdd(`/users/create/`, { ...data });
      console.log("res..",response)
      if (response.status >= 200 && response.status < 300) {
        // handleAdd(response.data);
        userDetailsForm.resetFields();
        clearData();
        // history.push(`/pages/settings/manage-users`)
        onSuccess(response.data);
        return response.data;
      } else {
        throw new Error(response);
        
      }
    } catch (error) {
      console.log("Error",error);
      if (error.response.data?.username) {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                {error.response.data?.username?.username}
              </p>
            </div>
          ),
        });
      } 
      else if(error.response.data?.email){
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                {error.response.data?.email?.email}
              </p>
            </div>
          ),
        });
      }
     else if(error.response.data?.phone){
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                {error.response.data?.phone?.phone}
              </p>
            </div>
          ),
        });
      }
      else if(error.response.data?.latitude){
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                {error.response.data?.latitude[0]}
              </p>
            </div>
          ),
        });
      }

    else if(error.response.data?.longitude){
            Modal.error({
              icon: (
                <span className="remix-icon">
                  <RiAlertLine />
                </span>
              ),
              title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
              content: (
                <div>
                  <p className="hp-p1-body hp-text-color-black-80">
                    {error.response.data?.longitude[0]}
                  </p>
                </div>
              ),
            });
          }
      // else {
      //   // OnFailure(error.response.data.error[0]);
      // }
      // OnFailure(error.message);
      // OnFailure(error.message);
      throw error.message;
    }
  }
);
export const putUserLocation = createAsyncThunk(
  "/putUserLocation",
  async (payload) => {
    const { id, data, history, clearData } = payload;
    try {
      const response = await postEdit(`/users/locationpermission/${id}`, { ...data });
      console.log("res..",response)
      if (response.status >= 200 && response.status < 300) {
        // onSuccess(response.data);
        history.push(`/pages/settings/manage-users`);
        clearData();
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                User Added Successfully.!
              </p>
            </div>
          ),
        });
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      console.log(error.response);
      // if (error.response.data?.group_ids) {
      //   // OnFailure(error.response.data.group_ids[0]);
      // } else {
      //   // OnFailure(error.response.data.error[0]);
      // }
      // OnFailure(error.message);
      // OnFailure(error.message);
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Added User location permission. Please try
              again!
            </p>
          </div>
        ),
      });
      throw error.message;
    }
  }
);

export const userNameValidation = createAsyncThunk(
  "/userNameValidation",
  async (payload) => {
    const { data, onSuccess, onFailure } = payload;
    console.log("user name payyload",payload)
    try {
      const response = await postAdd("/users/username/validation/", data);
      onSuccess(response);
    } catch (error) {
      if (error.response.status === 400) {
        onFailure(error.response.data.username[0]);
        // yield put(InputChangeValue({ key: "username", value: "" }));
      } else {
        onFailure("Sorry! Getting from server side issue!");
      }
    }
  }
);
export const userEmailValidation = createAsyncThunk(
  "/userEmailValidation",
  async (payload) => {
    const { data, onSuccess, onFailure } = payload;
    try {
      const response = await postAdd("/users/useremail/validation/", payload);
      onSuccess(response);
    } catch (error) {
      if (error.response.status === 400) {
        onFailure(error.response.data.error[0]);
      } else {
        onFailure("Sorry! Getting from server side issue!");
      }
    }
  }
);
export const userMobileValidation = createAsyncThunk(
  "/userMobileValidation",
  async (payload) => {
    const { data, onSuccess, onFailure } = payload;
    try {
      const response = await postAdd("/users/userphone/validation/", payload);
      onSuccess(response);
    } catch (error) {
      if (error.response.status === 400) {
        onFailure(error.response.data.error[0]);
      } else {
        onFailure("Sorry! Getting from server side issue!");
      }
    }
  }
);
export const userActive = createAsyncThunk("/userActive", async (payload) => {
  const { id, onSuccess, OnFailure } = payload;
  try {
    const response = await postAdd(`/users/useractive/${id}`, {});
    if (response.status >= 200 && response.status < 300) {
      onSuccess(response.data);
      return response.data;
    } else {
      throw new Error(response);
    }
  } catch (error) {
    if (error.response) {
      OnFailure(error.response.data.phone);
    } else {
      OnFailure("Sorry! Getting from server side issue!");
    }
    throw error.message;
  }
});
export const userInActive = createAsyncThunk(
  "/userInActive",
  async (payload) => {
    const { id, onSuccess, onFailure } = payload;
    try {
      const response = await postAdd(`/users/userinactive/${id}`, {});
      if (response.status >= 200 && response.status < 300) {
        onSuccess(response.data);
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      if (error.response) {
        onFailure(error.response.data.phone);
        throw error.response.data.phone;
      } else {
        onFailure("Sorry! Getting from server side issue!");
        throw error.message;
      }
    }
  }
);
export const customerActive = createAsyncThunk("/customerActive", async (payload) => {
  const { id, onSuccess, OnFailure } = payload;
  try {
    const response = await postAdd(`/users/admin/customer/active/${id}/`, {});
    if (response.status >= 200 && response.status < 300) {
      onSuccess(response.data);
      return response.data;
    } else {
      throw new Error(response);
    }
  } catch (error) {
    if (error.response) {
      OnFailure(error.response.data.phone);
    } else {
      OnFailure("Sorry! Getting from server side issue!");
    }
    throw error.message;
  }
});
export const customerInActive = createAsyncThunk(
  "/customerInActive",
  async (payload) => {
    const { id, onSuccess, onFailure } = payload;
    try {
      const response = await postAdd(`/users/admin/customer/inactive/${id}/`, {});
      if (response.status >= 200 && response.status < 300) {
        onSuccess(response.data);
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      if (error.response) {
        onFailure(error.response.data.phone);
        throw error.response.data.phone;
      } else {
        onFailure("Sorry! Getting from server side issue!");
        throw error.message;
      }
    }
  }
);


// export const postMangeUsersDataById = createAsyncThunk(
//   "/postMangeUsersDataById",
//   async (payload) => {
//     try {
//       const response = await postAdd(`/masters/branch/${id}`, { ...payload });
//       if (response.status >= 200 && response.status < 300) {
//         return response.data;
//       } else {
//         throw new Error(response);
//       }
//     } catch (error) {
//       throw error.message;
//     }
//   }
// );

export const changePassword = createAsyncThunk(
  "/changePassword",
  async (payload) => {
    const { data, onSuccess, onFailure } = payload;
    console.log(data)
    try {
      const response = await axiosInstance.put(`/users/changepassword/`, {
        ...data,
      });
      if (response.status >= 200 && response.status < 300) {
        onSuccess(response.data);
        // handleAdd(id);
        // userDetailsForm.resetFields();
        // clearData();
        // history.push(`/pages/settings/manage-users`)
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      onFailure("Sorry! Getting from server side issue!");
      throw error.message;
    }
  }
);

export const editMangeUsersDataById = createAsyncThunk(
  "/editMangeUsersDataById",
  async (payload) => {
    const { id, data, userDetailsForm, clearData, history, handleAdd,onSuccess } = payload;
    try {
      const response = await axiosInstance.put(`/users/${id}`, {
        ...data,
      });
      if (response.status >= 200 && response.status < 300) {
        onSuccess(response.data);
        handleAdd(id);
        userDetailsForm.resetFields();
        clearData();
        history.push(`/pages/settings/manage-users`)
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      if (error.response) {
        // onFailure(error.response.data.message);
        if (error.response.data?.username) {
          Modal.error({
            icon: (
              <span className="remix-icon">
                <RiAlertLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  {error.response.data?.username?.username}
                </p>
              </div>
            ),
          });
        } 
        else if(error.response.data?.email){
          Modal.error({
            icon: (
              <span className="remix-icon">
                <RiAlertLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  {error.response.data?.email?.email}
                </p>
              </div>
            ),
          });
        }
       else if(error.response.data?.phone){
          Modal.error({
            icon: (
              <span className="remix-icon">
                <RiAlertLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  {error.response.data?.phone?.phone}
                </p>
              </div>
            ),
          });
        }
        else if(error.response.data?.latitude){
          Modal.error({
            icon: (
              <span className="remix-icon">
                <RiAlertLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  {error.response.data?.latitude[0]}
                </p>
              </div>
            ),
          });
        }
  
      else if(error.response.data?.longitude){
              Modal.error({
                icon: (
                  <span className="remix-icon">
                    <RiAlertLine />
                  </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                  <div>
                    <p className="hp-p1-body hp-text-color-black-80">
                      {error.response.data?.longitude[0]}
                    </p>
                  </div>
                ),
              });
            }
        throw error.response.data.phone;
      } else {
        // onFailure("Sorry! Getting from server side issue!");
        throw error.message;
      }
    }
  }
);
export const deleteMangeUsersDataById = createAsyncThunk(
  "/deleteMangeUsersDataById",
  async (payload) => {
    const { url } = payload;
    try {
      const response = await postDelete(url);
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);

export const getUserStates = createAsyncThunk(
  "/getUserStates",
  async (payload, { getState, dispatch, rejectWithValue }) => {

    var params = seralizeParams(payload);
    try {
      const response = await getParamsList(
        "/masters/states/mini/",
        params
      );
      if (response.results) {
        const manageUserState = getState().manageUsers;
        var statesList = response?.results?.map((userState) => {
          if (manageUserState.userSelectedStatesList.indexOf(userState.id) !== -1) {
            userState.state_checked = true;
          } else {
            userState.state_checked = false;
          }
          return userState;
        });
        const configHome = statesList
          .filter((z) => z.state_checked)
          .map((userState) => {
            return dispatch(getUserZones({ state: userState.id }));
          });

        const promise = await Promise.all(configHome);
        const promisePayload = promise.map(zone => zone.payload);
        console.log(promisePayload)
        return {
          statesList: statesList.map(e => {
            console.log(e)
            return {
              ...e,
              zones: promisePayload.find(zone => zone.state_id == e.id)?.zoneList
            }

          })
        };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);
export const getUserZones = createAsyncThunk(
  "/getUserZones",
  async (payload, { getState, dispatch }) => {
    var params = seralizeParams(payload);
    try {

      const response = await getParamsList(
        "/masters/zone/mini/",
        params
      );
      console.log(response)
      if (response.results) {
        const manageUserState = getState().manageUsers;
        var zoneList = response?.results?.map((zone) => {
          if (
            manageUserState.userSelectedZonesList.indexOf(zone.id) !== -1
          ) {
            zone.zone_checked = true;
          } else {
            zone.zone_checked = false;
          }
          zone.state_id = payload.state;
          return zone;
        });
        const configHome = zoneList
          .filter((d) => d.zone_checked)
          .map((zone) => {
            return dispatch(getUserCities({ zone: zone.id, state: payload.state }));
          });
        const promise = await Promise.all(configHome);
        const promisePayload = promise.map(city => city.payload);
        console.log(promisePayload)
        return {
          zoneList: zoneList.map(e => {
            console.log(e)

            return {
              ...e,
              // cities: promise.map(city => city.payload.
              //   citiesList
              // ).flat()
              cities: promisePayload.find(city => (city.state_id == e.state_id) && (city.zone_id == e.id))?.citiesList
            }
          }), state_id: payload.state
        };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      return error.message;
    }
  }
);
export const getUserCities = createAsyncThunk(
  "/getUserCities",
  async (payload, { getState, dispatch }) => {
    var params = seralizeParams(payload);
    try {

      const response = await getParamsList(
        "/masters/citys/mini/",
        params
      );
      if (response) {
        const manageUserState = getState().manageUsers;
        var citiesList = response.results.map((city) => {
          if (
            manageUserState.userSelectedCityList.indexOf(city.id) !== -1
          ) {
            city.city_checked = true;
          } else {
            city.city_checked = false;
          }
          city.state_id = payload.state;
          city.zone_id = payload.zone;
          return city;
        });
        const configHome = citiesList
          .filter((d) => d.city_checked)
          .map((city) => {
            return dispatch(getUserAreas({ city: city.id, state: payload.state, zone: payload.zone }));
          });
        const promise = await Promise.all(configHome);
        const promisePayload = promise.map(area => area.payload);
        console.log(promisePayload)
        return {
          citiesList: citiesList.map(e => {
            console.log(e)
            return {
              ...e,
              // areas: promise.map(area => area.payload.
              //   areasList
              // ).flat()
              areas: promisePayload.find(area => (area.state_id == e.state_id) && (area.zone_id == e.zone_id) && (area.city_id == e.id))?.
                areasList
            }
          }), state_id: payload.state, zone_id: payload.zone
        };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      return error.message;
    }
  }
);
export const getUserAreas = createAsyncThunk(
  "/getUserAreas",
  async (payload, { getState, dispatch }) => {
    var params = seralizeParams(payload);
    try {

      const response = await getParamsList(
        "/masters/area/mini/",
        params
      );
      if (response) {
        const manageUserState = getState().manageUsers;
        var areasList = response.results.map((area) => {
          if (
            manageUserState.userSelectedAreaList.indexOf(area.id) !== -1
          ) {
            area.area_checked = true;
          } else {
            area.area_checked = false;
          }
          area.state_id = payload.state;
          area.zone_id = payload.zone;
          area.city_id = payload.city;
          return area;
        });
        const configHome = areasList
          .filter((d) => d.area_checked)
          .map((area) => {
            return dispatch(getUserLocation({ area: area.id, state: payload.state, zone: payload.zone, city: payload.city }));
          });
        const promise = await Promise.all(configHome);
        const promisePayload = promise.map(area => area.payload);
        console.log(promisePayload)

        return {
          areasList: areasList.map(e => {
            console.log(e)
            return {
              ...e,
              // locations: promise.map(location => location.payload.
              //   locationList
              // ).flat()
              locations: promisePayload.find(location => (location.state_id == e.state_id) && (location.zone_id == e.zone_id) && (location.city_id == e.city_id) && location.area_id == e.id)?.locationList
            }
          }), state_id: payload.state, zone_id: payload.zone, city_id: payload.city
        };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      return error.message;
    }
  }
);
export const getUserLocation = createAsyncThunk(
  "/getUserLocation",
  async (payload, { getState, dispatch }) => {
    var params = seralizeParams(payload);
    try {

      const response = await getParamsList(
        "/masters/location/mini/",
        params
      );
      if (response) {
        const manageUserState = getState().manageUsers;
        var locationList = response.results.map((location) => {
          if (
            manageUserState.userSelectedLocationList.indexOf(location.id) !== -1
          ) {
            location.location_checked = true;
          } else {
            location.location_checked = false;
          }
          location.state_id = payload.state;
          location.zone_id = payload.zone;
          location.city_id = payload.city;
          location.area_id = payload.area;
          return location;
        });
        return { locationList, state_id: payload.state, zone_id: payload.zone, city_id: payload.city, area_id: payload.area };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      return error.message;
    }
  }
);
