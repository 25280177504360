import { createSlice } from "@reduxjs/toolkit";
import {
	deleteUserAccount,
	deleteByOTP,
	userdeactive,
} from "./deleteUserActions";

const deleteUserSlice = createSlice({
	name: "deleteuser",
	initialState: {
		userData: {},
		mobileNumberDisable: false,
		OtpInputDisable: false,
		loading: false,
		error: "",
		status: "",
	},

	reducers: {
		InputChangeValues: (state, action) => {
			return {
				...state,
				userData: {
					...action.payload,
				},
			};
		},
		clearData: (state, action) => {
			return {
				...state,
				userData: {},
				mobileNumberDisable: false,
				OtpInputDisable: false,
				loading: false,
				error: "",
				status: "",
			};
		},
	},

	extraReducers: (builder) => {
		builder
			.addCase(deleteUserAccount.pending, (state, action) => {
				state.status = "loading";
				state.loading = true;
				state.mobileNumberDisable = true;
				state.OtpInputDisable = false;
			})
			.addCase(deleteUserAccount.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.loading = false;
				state.mobileNumberDisable = true;
				state.userData = action.payload;
				state.OtpInputDisable = true;
			})
			.addCase(deleteUserAccount.rejected, (state, action) => {
				state.status = "failed";
				state.loading = false;
				state.mobileNumberDisable = false;
				state.OtpInputDisable = false;
				state.error = action.error.message;
			})
			.addCase(deleteByOTP.pending, (state, action) => {
				state.status = "loading";
				state.loading = true;
				state.OtpInputDisable = true;
			})
			.addCase(deleteByOTP.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.loading = false;
				state.OtpInputDisable = false;
				state.mobileNumberDisable = false;
				state.userData = action.payload;
			})
			.addCase(deleteByOTP.rejected, (state, action) => {
				state.status = "failed";
				state.loading = false;
				state.OtpInputDisable = true;
				state.mobileNumberDisable = true;
				state.error = action.error.message;
			})
			.addCase(userdeactive.pending, (state, action) => {
				state.status = "loading";
				state.loading = true;
				state.OtpInputDisable = true;
			})
			.addCase(userdeactive.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.loading = false;
				state.mobileNumberDisable = false;
				state.OtpInputDisable = false;
				state.userData = {};
			})
			.addCase(userdeactive.rejected, (state, action) => {
				state.status = "failed";
				state.loading = false;
				state.mobileNumberDisable = false;
				state.OtpInputDisable = true;
				state.error = action.error.message;
			});
	},
});

export const { InputChangeValues, clearData } = deleteUserSlice.actions;

export default deleteUserSlice.reducer;
