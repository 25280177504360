import { createSlice } from "@reduxjs/toolkit";
import { getLocationsList, getLocationByID, getLocationByCompany } from "./locationsAction";

const locationsSlice = createSlice({
    name: "locations",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        listCount: 0,
        locationlist: [],
        locationParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        companyByLocations: []
    },
    reducers: {

        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },
        SetLocationParams: (state, action) => {
            return {
                ...state,
                locationParams: {
                    ...state.locationParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
        changeFilterParams: (state, action) => {
            return {
              ...state,
              filterParams: action.payload,
            };
          },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLocationsList.pending, (state, action) => {
                state.status = "getLocationsList loading";
                state.loading = true;
            })
            .addCase(getLocationsList.fulfilled, (state, action) => {
                state.status = "getLocationsList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.locationParams?.page_size
                );

                state.locationlist = response.results;
                state.listCount = response.count;
                state.locationParams = {
                    ...state.locationParams,
                    no_of_pages: noofpages,
                };
            })
            .addCase(getLocationsList.rejected, (state, action) => {
                state.status = "getLocationsList failed";
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getLocationByID.pending, (state, action) => {
                state.status = "getLocationByID loading";
                state.loading = true;
            })
            .addCase(getLocationByID.fulfilled, (state, action) => {
                state.status = "getLocationByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
            }
            )
            .addCase(getLocationByID.rejected, (state, action) => {
                state.status = "getLocationByID failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getLocationByCompany.pending, (state, action) => {
                state.status = "getLocationByCompany loading";
                state.loading = true;
            })
            .addCase(getLocationByCompany.fulfilled, (state, action) => {
                state.status = "getLocationByCompany succeeded";
                state.loading = false
                state.companyByLocations = action.payload.results;
            }
            )
            .addCase(getLocationByCompany.rejected, (state, action) => {
                state.status = "getLocationByCompany failed";
                state.loading = false;
                state.error = action.error.message;
            })

    }

});
export const {
    InputChangeValue,
    SetLocationParams,
    changeFilterParams,
    isModelVisible
} = locationsSlice.actions;

export default locationsSlice.reducer;