import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { getManageCompany, manageCompanyAdd, getCompanyLogoUpload } from "./manageCompanyActions";

const manageCompanySlice = createSlice({
  name: "manageCompany",
  initialState: {
    listCount: 0,
    // manageCompanyData: [],
    manageCompanyData: {},
    manageCompanyLogo: {},
    model: false,
    companyLogoLoading: false,
    manageCompanyParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          // manageCompanyData: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        manageCompanyData: {
          ...state.manageCompanyData,
          [action.payload.key]: action.payload.value,
        },
      };
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getManageCompany.pending, (state, action) => {
        state.loading = true;
        state.status = "getManageCompany pending";
      })
      .addCase(getManageCompany.fulfilled, (state, action) => {

        state.status = "getManageCompany succeeded";
        state.loading = false;
        state.manageCompanyData = {
          COMPANY__NAME: action.payload.COMPANY__NAME,
          COMPANY__SIMPLENAME: action.payload.COMPANY__SIMPLENAME,
          COMPANY__EMAIL: action.payload.COMPANY__EMAIL,
          COMPANY__MOBILE: action.payload.COMPANY__MOBILE,
          COMPANY__ALTERNATEMOBILE: action.payload.COMPANY__ALTERNATEMOBILE,
          COMPANY__WEBSITE: action.payload.COMPANY__WEBSITE,
          COMPANY__GSTNO: action.payload.COMPANY__GSTNO,
          COMPANY__ADDRESS: action.payload.COMPANY__ADDRESS,
        }
        state.manageCompanyLogo = {
          COMPANY__LOGO: action.payload.COMPANY__LOGO,
          COMPANY__SMALLLOGO: action.payload.COMPANY__SMALLLOGO,
        }
      })
      .addCase(getManageCompany.rejected, (state, action) => {
        state.status = "getManageCompany failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(manageCompanyAdd.pending, (state, action) => {
        state.loading = true;
        state.status = "manageCompanyAdd pending";
      })
      .addCase(manageCompanyAdd.fulfilled, (state, action) => {
        state.status = "manageCompanyAdd succeeded";
        state.loading = false;
      })
      .addCase(manageCompanyAdd.rejected, (state, action) => {
        state.status = "manageCompanyAdd failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCompanyLogoUpload.pending, (state, action) => {
        state.loading = true;
        state.companyLogoLoading = true
        state.status = "getCompanyLogoUpload pending";
      })
      .addCase(getCompanyLogoUpload.fulfilled, (state, action) => {

        state.status = "getCompanyLogoUpload succeeded";
        state.companyLogoLoading = false
        state.loading = false;
      })
      .addCase(getCompanyLogoUpload.rejected, (state, action) => {
        state.status = "getCompanyLogoUpload failed";
        state.loading = false;
        state.companyLogoLoading = false
        state.error = action.error.message;
      })
  }
});

// Action creators are generated for each case reducer function
export const {
  isModelVisible,
  InputChangeValue,
} = manageCompanySlice.actions;

export default manageCompanySlice.reducer;
