import React, { useEffect } from "react";
import { Row, Col, Input, Card, Pagination, Popconfirm, Spin, Image, Switch, Form } from "antd";
import { Table, Space } from "antd";
import { RiErrorWarningLine } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import { Selector } from "../../../../store";
import { Link } from "react-router-dom";
import { UploadOutlined } from '@ant-design/icons';
import MasterPageTitle from "../../../../layout/components/content/master-page-title";
import TableComponent from "../../../components/components-page/TableComponent";
import {
    getJattuList, getJattuByID
} from "../../../../store/Masters/Jattu/jattuAction";

import { SetJattuParams } from "../../../../store/Masters/Jattu/jattuSlice";

function Jattu() {

    const dispatch = useDispatch();

    const jattulist = Selector("masters", "jattu").jattulist;
    const noofpages = useSelector((state) => state.masters.jattu.noofpages);
    const listCount = useSelector((state) => state.masters.jattu.listCount);
    const jattuParams = useSelector((state) => state.masters.jattu.jattuParams);
    const loading = useSelector((state) => state.masters.jattu.loading);
    const userAccessList = useSelector((state) => state.system.userAccessList);
    const [form] = Form.useForm();

    const columnClass =
        "hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 hp-font-weight-600 ";

    useEffect(() => {
        dispatch(getJattuList({ page: 1, search: "", page_size: 10 }));
    }, []);

    const columns = [
        {
            title: "S.No",
            dataIndex: "index",
            width: "5%",
        },
        {
            title: "Name",
            render: (row) => <span>{row.name}</span>,
            filterMode: "tree",
            filterSearch: true,
            width: "30%",
        },
    ];
    const data = jattulist?.map((row, key) => ({
        ...row,
        index: (jattuParams.page - 1) * jattuParams.page_size + key + 1,
    }));

    function onPageChange(page, pageSize) {
        dispatch(SetJattuParams({
            ...jattuParams,
            page_size: pageSize,
            page: page == 0 ? 1 : page,
        }));
        dispatch(getJattuList({
            ...jattuParams,
            page_size: pageSize,
            page: page == 0 ? 1 : page,
        }));
    }

    function onSearch(val) {
        dispatch(SetJattuParams({ ...jattuParams, page: 1, search: val }));
        dispatch(getJattuList({ ...jattuParams, page: 1, search: val }));
    }
    function showTotal(total) {
        return "Total " + total + " items";
    }
    const { Search } = Input;

    return (
        <Row gutter={[12, 12]} className="hp-mt-32">
            <MasterPageTitle
                prefix={"Masters"}
                postfix={"jattu"}
                pageTitle="Jattu"
                goBack={true}
            // setIsModalVisible={openModal}
            // isModalVisible={model}
            />

            <Col span={24}>
                <Card>
                    <Row>
                        <Col span={12} xs={24} sm={24} md={12} className="hp-mb-12">
                            <Search
                                placeholder="Search..."
                                allowClear
                                onChange={(e) => {
                                    if (e.target.value == "") {
                                        dispatch(getJattuList({
                                            ...jattuParams,
                                            page: 1,
                                            search: "",
                                        }));
                                        dispatch(SetJattuParams({
                                            ...jattuParams,
                                            page: 1,
                                            search: "",
                                        }));
                                    }
                                }}
                                onSearch={(value) => {
                                    onSearch(value);
                                }}
                            />
                        </Col>

                        <Col span={24}>
                            <Spin tip="Loading..." spinning={loading} delay={500}>
                                <TableComponent
                                    page={jattuParams.page}
                                    count={listCount}
                                    pageSize={jattuParams.page_size}
                                    columns={columns}
                                    data={data}
                                    loading={loading}
                                    onPageChange={onPageChange}
                                    showTotal={showTotal}
                                    scroll={true}
                                />
                            </Spin>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    )
}

export default Jattu;