import { createSlice } from "@reduxjs/toolkit";
import { getTruckSheetList, getTruckSheetByID } from "./trucksheetAction";

const truckSheetSlice = createSlice({
    name: "truckSheet",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,        
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        listCount: 0,
        truckSheetlist: [],
        vehicleInwardItems:[],
        truckSheetParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        
    },
    reducers: {
       
        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },   
        SetTruckSheetParams: (state, action) => {
            return {
                ...state,
                truckSheetParams: {
                    ...state.truckSheetParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
        setTrucksheetItemsList: (state, action) => {
            return{
                ...state,
                vehicleInwardItems: action.payload
            }          
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTruckSheetList.pending, (state, action) => {
                state.status = "getTruckSheetList loading";
                state.loading = true;
            })
            .addCase(getTruckSheetList.fulfilled, (state, action) => {
                state.status = "getTruckSheetList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.truckSheetParams?.page_size
                );

                state.truckSheetlist = response.results;
                state.listCount = response.count;
                state.truckSheetParams = {
                    ...state.truckSheetParams,
                    no_of_pages: noofpages,
                };                
            })
            .addCase(getTruckSheetList.rejected, (state, action) => {
                state.status = "getTruckSheetList failed";
                state.loading = false;
                state.error = action.error.message;
            })
            
            .addCase(getTruckSheetByID.pending, (state, action) => {
                state.status = "getTruckSheetByID loading";
                state.loading = true;
            })
            .addCase(getTruckSheetByID.fulfilled, (state, action) => {
                state.status = "getTruckSheetByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
            }
            )
            .addCase(getTruckSheetByID.rejected, (state, action) => {
                state.status = "getTruckSheetByID failed";
                state.loading = false;
                state.error = action.error.message;
            })
           
    }

});
export const {
    InputChangeValue,
    SetTruckSheetParams,
    isModelVisible,
    setTrucksheetItemsList
} = truckSheetSlice.actions;

export default truckSheetSlice.reducer;