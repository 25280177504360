import { createSlice } from "@reduxjs/toolkit";
import { getStockDocumentList, getStockDocumentByID } from "./stockDocumentAction";

const stockDocumentSlice = createSlice({
    name: "stockDocument",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,        
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        listCount: 0,
        stockdocumentlist: [],
        stockDocumentParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        
    },
    reducers: {
       
        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },   
        SetStockDocumentParams: (state, action) => {
            return {
                ...state,
                stockDocumentParams: {
                    ...state.stockDocumentParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getStockDocumentList.pending, (state, action) => {
                state.status = "getStockDocumentList loading";
                state.loading = true;
            })
            .addCase(getStockDocumentList.fulfilled, (state, action) => {
                console.log(action.payload.response)
                state.status = "getStockDocumentList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.stockDocumentParams?.page_size
                );

                state.stockdocumentlist = response.results;
                state.listCount = response.count;
                state.stockDocumentParams = {
                    ...state.stockDocumentParams,
                    no_of_pages: noofpages,
                };
            })
            .addCase(getStockDocumentList.rejected, (state, action) => {
                state.status = "getStockDocumentList failed";
                state.loading = false;
                state.error = action.error.message;
            })
            
            .addCase(getStockDocumentByID.pending, (state, action) => {
                state.status = "getStockDocumentByID loading";
                state.loading = true;
            })
            .addCase(getStockDocumentByID.fulfilled, (state, action) => {
                state.status = "getStockDocumentByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
            }
            )
            .addCase(getStockDocumentByID.rejected, (state, action) => {
                state.status = "getStockDocumentByID failed";
                state.loading = false;
                state.error = action.error.message;
            })
           
    }

});
export const {
    InputChangeValue,
    SetStockDocumentParams,
    isModelVisible
} = stockDocumentSlice.actions;

export default stockDocumentSlice.reducer;