import React, { useEffect, useMemo, useState } from "react";
import { useLocation, Link } from "react-router-dom";

import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { getMenuItems } from "../../../../store/system/systemActions";
import { useDispatch } from "react-redux";

import { Menu } from "antd";
import * as Icons from "@ant-design/icons";

import { getSideMenuItems } from "../../../../store/system/systemSlice";

const { SubMenu } = Menu;

function MenuItem(props) {
  const [selectedItemId, setSelectedItemId] = useState(null);



  const onSelect = (itemId) => {
    setSelectedItemId(itemId);
  };

  const dispatch = useDispatch();
  const { onClose } = props;
  useEffect(() => {
    dispatch(getMenuItems({ from: "sidemenu", code: "MENU0004" }));
  }, []);

  // Redux
  const customise = useSelector((state) => state.customise);
  const sideMenuItemsList = useSelector(
    (state) => state.system.sideMenuItemsList
  );

  // Location
  const location = useLocation();
  const { pathname } = location;

  const splitLocation = pathname.split("/");

  // Menu
  const splitLocationUrl =
    splitLocation[splitLocation.length - 2] +
    "/" +
    splitLocation[splitLocation.length - 1];

  const subMenuItem = sideMenuItemsList?.submenus?.map((item, index) => {
    if (item.menuitems.length) {
      let Icon = Icons[item.icon];
      return (
        <SubMenu key={index} icon={<Icon />} title={item.name}>
          {item.menuitems.map((childrens, index) => {
            if (!childrens.children) {
              const childrenNavLink = childrens.link.split("/");
              let Icon = Icons[childrens.icon];
              return (
                // Level 2
                <Menu.Item
                  key={childrens.id}
                  icon={<Icon />}
                  className={
                    // splitLocationUrl ===
                    //   childrenNavLink[childrenNavLink.length - 2] +
                    //   "/" +
                    //   childrenNavLink[childrenNavLink.length - 1]
                    childrenNavLink[1] == splitLocation[1]
                      ? "ant-menu-item-selected"
                      : "ant-menu-item-selected-in-active"
                  }
                  onClick={onClose}>
                  <Link to={childrens.link}>{childrens.title}</Link>
                </Menu.Item>
              );
            } else {
              return (
                // Level 3
                <SubMenu key={childrens.id} title={childrens.title}>
                  {childrens.children.map((childItem, index) => {
                    const childrenItemLink = childItem.navLink.split("/");
                    return (
                      <Menu.Item
                        key={childItem.id}
                        className={
                          // splitLocationUrl ===
                          //   childrenItemLink[childrenItemLink.length - 2] +
                          //   "/" +
                          //   childrenItemLink[childrenItemLink.length - 1]
                          childrenItemLink[1] == splitLocation[1]
                            ? "ant-menu-item-selected"
                            : "ant-menu-item-selected-in-active"
                        }
                        onClick={onClose}>
                        <Link to={childItem.link}>{childItem.title}</Link>
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            }
          })}
        </SubMenu>
      );
    }
  });
  const menuItem = sideMenuItemsList?.menuitems?.map((item, index) => {
    const itemNavLink = item.link.split("/");
    let Icon = item?.icon?.startsWith("<") && item?.icon?.endsWith(">") ? Icons[item.icon.slice(1, -2).trim()] : Icons[item.icon];
    return (
      // Level 1
      <Menu.Item
        key={item.id}
        icon={<Icon />}
        onClick={onClose}
        className={
          // splitLocation[splitLocation.length - 2] +
          //   "/" +
          //   splitLocation[splitLocation.length - 1] ===
          //   itemNavLink[itemNavLink.length - 2] +
          //   "/" +
          //   itemNavLink[itemNavLink.length - 1]
          itemNavLink[1] == splitLocation[1]
            ? "ant-menu-item-selected"
            : "ant-menu-item-selected-in-active"
        }>
        <Link to={item.link}>{item.name}</Link>
      </Menu.Item>
    );
  });

  return (
    <>
      <Menu
        mode="inline"
        defaultOpenKeys={[
          splitLocation.length === 5
            ? splitLocation[splitLocation.length - 3]
            : null,
          splitLocation[splitLocation.length - 2],
        ]}
        theme={customise.theme == "light" ? "light" : "dark"}>
        {subMenuItem}
        {menuItem}
      </Menu>
    </>
  );
}

export default (MenuItem);
