import axios from "axios";
import { axiosInstance, BaseURL, TOKEN_PREFIX } from "./AxiosHelper";
import moment from "moment";
import fileDownload from "js-file-download";
import { serialize } from "object-to-formdata";


const toSentenceCase = (string) => {
	// return string.charAt(0).toUpperCase() + string.slice(1);
	return string.split(" ").map(string => string ? string.charAt(0).toUpperCase() + string.slice(1) : null).filter(string => string).join(" ")

};

const addParams = (payload) => {
	var data = payload;
	let params = {};
	for (const k in data) {
		if (Object.hasOwnProperty.call(data, k)) {
			if (k === "currentSort" || k === "sortOrder") {
				params.ordering = data.sortOrder + data.currentSort;
			} else {
				if (data[k] !== "" && k !== null && k != "no_of_pages") {
					if (k == "start_date") {
						params[k] = moment(data[k]).format("YYYY-MM-DD");
					} else if (k == "end_date") {
						params[k] = moment(data[k]).format("YYYY-MM-DD");
					} else {
						params[k] = data[k];
					}
				}
			}
		}
	}
	return params;
};

const simplePost = (url, data) => {
	return axios
		.post(BaseURL + url, data)
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;

			return response;
		})
		.catch((err) => {
			throw err.response;
		});
};
// const VendorLogin = (url, data) => {
//   return axios
//     .post(BaseURL + url, data)
//     .then((response) => {
//       if (response.status === 400 || response.status === 500)
//         throw response.data;
//       axiosInstance.defaults.headers["Authorization"] =
//         TOKEN_PREFIX + response.data.access_token;

//       return response;
//     })
//     .catch((err) => {
//       throw err.response.data;
//     });
// };
const postLogin = (url, data) => {
	return axios
		.post(BaseURL + url, data)
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;

			axiosInstance.defaults.headers["Authorization"] =
				TOKEN_PREFIX + response.data.tokens.access;
			return response;
		})
		.catch((err) => {
			throw err.response;
		});
};
const postLogout = (url, data) => {
	return axiosInstance
		.post(url, data)
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response;
			axiosInstance.defaults.headers["Authorization"] = "";
			return response;
		})
		.catch((err) => {
			var message;

			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = err.response.data;
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw err;
		});
};

const getList = (url) => {
	// const user = localStorage.getItem("authUser");
	// let sjson = JSON.parse(user);
	return axiosInstance
		.get(url)
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;
			return response;
		})
		.catch((err) => {
			throw err[1];
		});
};

const getParamsList = (url, params) => {
	// const user = localStorage.getItem("authUser");
	// let sjson = JSON.parse(user);
	return axiosInstance
		.get(url, {
			params: params,
		})
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;
			return response.data;
		})
		.catch((err) => {
			throw err[1];
		});
};

// postForgetPwd
const postAdd = (url, data, params = {}, signal) => {
	// const user = localStorage.getItem("authUser");
	// let sjson = JSON.parse(user);

	return axiosInstance
		.post(url, data, {
			params: params,
			// signal: signal
		})
		.then((response) => {
			if (response.status === 400 || response.status === 500) {
				throw response;
			}

			return response;
		})
		.catch((err) => {
			var message;

			// if (signal.aborted) {
			// 	if (signal.reason) {
			// 		message = `Request aborted with reason: ${signal.reason}`;
			// 		return []
			// 	} else {
			// 		message = "Request aborted but no reason was given."
			// 		return []
			// 	}
			// }

			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = err.response.data;
						break;
					default:
						message = err[1];
						break;
				}
			}

			console.log(message)

			throw err;
		});
};

const postFormData = (url, data) => {
	const formData = serialize(data);

	return axiosInstance
		.post(url, formData)
		.then((response) => {
			// console.log("postFormData THEN ", response);
			if (response.status === 400 || response.status === 500) {
				throw response;
			}

			return response;
		})
		.catch((err) => {
			// console.log("postFormData erorr", err);
			var message;

			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = err.response.data;
						break;
					case 413:
						message = "File must be smaller than 20 MB";
						break;
					default:
						// message = err[1];
						message = "Unable to upload the file";
						break;
				}
			}
			return message;
		});
};
const putImg = (url, data) => {
	// const user = localStorage.getItem("authUser");
	// let sjson = JSON.parse(user);
	// const formData = new FormData();
	// formData.append("file", data.file);
	const formData = serialize(data);

	return axiosInstance
		.put(url, formData, {
			headers: {

			}
		})
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;
			return response;
		})
		.catch((err) => {
			//throw err[1];

			var message;
			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = err.response.data;
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw message;
		});
};
const patchImg = (url, data) => {
	// const user = localStorage.getItem("authUser");
	// let sjson = JSON.parse(user);
	// const formData = new FormData();
	// formData.append(data.name, data.file);
	const formData = serialize(data);

	return axiosInstance
		.patch(url, formData)
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;
			return response;
		})
		.catch((err) => {
			//throw err[1];

			var message;
			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = err.response.data;
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw message;
		});
};
const postEdit = (url, data) => {
	// const user = localStorage.getItem("authUser");
	// let sjson = JSON.parse(user);
	// const formData = serialize(data);

	return axiosInstance
		.put(url, data)
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;
			return response;
		})
		.catch((err) => {
			var message;
			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = err.response.data;
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw err;
		});
};
const getDownloadFile = (url, params = {}) => {
	return axiosInstance
		.get(url, {
			params: params,
			headers: {
				"Access-Control-Expose-Headers": "Content-Disposition"
			}
		}, {
			responseType: "blob",

		})
		.then((response) => {
			if (response.status === 400 || response.status === 500) {
				throw response.data;
			}
			var filename = url?.split("/")[url?.split("/").length - 1];

			let headerLine = response.headers["content-disposition"];
			if (headerLine) {
				let startFileNameIndex = headerLine.indexOf('"') + 1;
				let endFileNameIndex = headerLine.lastIndexOf('"');
				filename = headerLine.substring(
					startFileNameIndex,
					endFileNameIndex
				);
			}
			fileDownload(response.data, filename);
		})
		.catch((err) => {
			var message;

			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = "Invalid credentials";
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw message;
		});
};
const postDownloadFile = (url, body, params = {}, signal) => {
	return axiosInstance
		.post(url, body, {
			params: params,
			responseType: "blob",
			signal: signal,
		})
		.then((response) => {
			if (response.status === 400 || response.status === 500) {
				throw response.data;
			}
			var filename = "filename.csv";
			let headerLine = `${body.model_name}.csv`;
			console.log(headerLine);
			if (headerLine) {
				// let startFileNameIndex = headerLine.indexOf('"') + 1;
				// let endFileNameIndex = headerLine.lastIndexOf('"');
				// filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
				filename = headerLine;
			}
			fileDownload(response.data, filename);
		})
		.catch((err) => {
			var message;

			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = "Invalid credentials";
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw message;
		});
};

const postDelete = (url) => {
	// const user = localStorage.getItem("authUser");
	// let sjson = JSON.parse(user);
	return axiosInstance
		.delete(url)
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;
			return response;
		})
		.catch((err) => {
			//throw err;

			var message;
			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = "Invalid credentials";
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw message;
		});
};
//--------------------------
const patchEdit = (url, data) => {
	// const user = localStorage.getItem("authUser");
	// let sjson = JSON.parse(user);
	return axiosInstance
		.patch(url, data)
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;
			return response;
		})
		.catch((err) => {
			var message;
			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = err.response.data;
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw message;
		});
};

// postForgetPwd
const postAddmultipart = (url, data) => {
	const user = localStorage.getItem("authUser");
	let sjson = JSON.parse(user);
	return axios
		.post(url, data, {
			headers: {
				Authorization: "Bearer  " + sjson.tokens.access,
				"content-type": "multipart/form-data",
			},
		})
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;
			return response;
		})
		.catch((err) => {
			//throw err[1];

			var message;
			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = "Invalid credentials";
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw message;
		});
};

const postEditmultipart = (url, data) => {
	const user = localStorage.getItem("authUser");
	let sjson = JSON.parse(user);
	return axios
		.put(url, data, {
			headers: {
				Authorization: "Token " + sjson.data.token,
				"content-type": "multipart/form-data",
			},
		})
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;
			return response;
		})
		.catch((err) => {
			//throw err[1];

			var message;
			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = "Invalid credentials";
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw message;
		});
};

const getRowData = (url) => {
	// const user = localStorage.getItem("authUser");
	// let sjson = JSON.parse(user);
	return axiosInstance
		.get(url)
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;
			return response;
		})
		.catch((err) => {
			//throw err;

			var message;
			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = "Invalid credentials";
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw message;
		});
};

const postUpload = (url, csvfile) => {
	const user = localStorage.getItem("authUser");
	let sjson = JSON.parse(user);
	// var formData = new FormData();
	// formData.append("file", csvfile.files[0]);
	return axios
		.post(url, csvfile, {
			headers: {
				Authorization: "Token " + sjson.data.token,
				"content-type": "multipart/form-data",
			},
		})
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;
			return response;
		})
		.catch((err) => {
			//throw err;

			var message;
			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = "Invalid credentials";
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw message;
		});
};

export {
	//casings
	toSentenceCase,

	addParams,
	simplePost,
	// VendorLogin,
	postLogin,
	postLogout,
	getList,
	getParamsList,
	postAdd,
	postFormData,
	putImg,
	patchImg,
	postEdit,
	getDownloadFile,
	postDownloadFile,
	//-----------
	patchEdit,
	postDelete,
	getRowData,
	postUpload,
	postAddmultipart,
	postEditmultipart,
};
