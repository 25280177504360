import { createAsyncThunk } from "@reduxjs/toolkit";
// Login Redux States

// AUTH related methods
import {
  postAdd,
  getList,
  getParamsList,
  postEdit,
  patchEdit,
  postDelete,
  getDownloadFile,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";



export const getBackUpDataBaseList = createAsyncThunk(
  "/getBackUpDataBaseList",
  async (payload) => {
    const { params } = payload;
    try {
      const response = await getParamsList(`/system/Database/Backup`, params);
      if (response) {
        return { response };
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error.message;
    }
  }
);


export const getDataBaseBackUP = createAsyncThunk(
  "/getDataBaseBackUP",
  async (payload) => {
      try {
          const response = await getList("/system/Database/DoBackup", {});
          if (response.data) {
              return { response: response.data }
          } else {
              throw new Error(response);
          }
      } catch (error) {
          return error.message;
      }
  }
);

export const DataBaseVerifyPassword = createAsyncThunk(
  "/DataBaseVerifyPassword",
  async (payload) => {
      const { id, onSuccess, onFailure } = payload;
      try {
          const response = await postAdd("/system/Database/downloadBackup/" + payload.dataBaseId, payload.obj);
          if (response.status >= 200 && response.status < 300) {
              onSuccess(response.data);
              window.location.href = response.data.file_url;
              return response.data;
          } else {
              throw new Error(response);
          }
      } catch (error) {
          if (error.response) {
              onFailure(error.response.data);
              throw error.response.data;
          } else {
              onFailure("Sorry! Getting from server side issue!");
              throw error.message;
          }
      }
  }
);




