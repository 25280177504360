import React from "react";

import { Col, Row, Button, Popconfirm } from "antd";
import { Link, useHistory } from "react-router-dom";
import { RiAddCircleFill, RiArrowLeftCircleFill, RiErrorWarningLine } from "react-icons/ri";
import { isMobile } from "react-device-detect";

export default function PageTitle(props) {
	const history = useHistory();

	const {
		pageTitle,
		pageText,
		goBack,
		addModelTile,
		addModelTile2,
		setIsModalVisible,
		setIsModalVisible2,
		status,
		children,
	} = props;

	return (
		<Col span={24} className="hp-mt-12">
			<Row>
				<Col span={16} xs={16} md={12} xl={16}>
					{goBack && (
						// <Popconfirm
						// 	placement="topRight"
						// 	title="Are you sure you want to close the page?"
						// 	onConfirm={() => history.goBack(-1)}
						// 	okText="Yes"
						// 	cancelText="No"
						// 	icon={
						// 		<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />
						// 	}
						// >
						<Link
							to="#"
							onClick={() => {

								history.goBack(-1);
							}}
							className="action-icon text-primary  hp-mr-12 hp-d-inline-block">
							<RiArrowLeftCircleFill
								className="remix-icon"
								size={24}
							/>
						</Link>

						// </Popconfirm>
					)}
					<Row className="hp-d-inline-block">
						<Col span={24}>
							{pageTitle && (
								<h3 className="hp-mb-2 hp-mt-4">{pageTitle}</h3>
							)}
						</Col>

						<Col span={24}>
							{pageText && (
								<p className="hp-mb-0 hp-p1-body">{pageText}</p>
							)}
						</Col>
					</Row>
				</Col>
				<Col span={8} md={12} xl={8}>
					<Row justify={"end"} gutter={[12, 12]}>
						{addModelTile && (
							<div className="hp-pl-4 hp-d-inline-block">
								<Button
									className=""
									type="primary"
									onClick={() => setIsModalVisible(true)}
									// block
									icon={
										<RiAddCircleFill
											className="remix-icon"
											size={22}
										/>
									}>
									{!isMobile && addModelTile}
								</Button>
							</div>
						)}
						{addModelTile2 && (
							<div className="hp-pl-4 hp-d-inline-block">
								<Button
									className="hp-pr-12"
									type="primary"
									onClick={() => setIsModalVisible2(true)}
									// block
									icon={
										<RiAddCircleFill
											className="remix-icon"
											size={22}
										/>
									}>
									{!isMobile && addModelTile2}
								</Button>
							</div>
						)}
						{status && (
							<Col span={24} md={12} lg={8}>
								<Button
									className="hp-pr-12"
									type="primary"
									// onClick={() => setIsModalVisible2(true)}
									size="middle"
									block>
									{status}
								</Button>
							</Col>
						)}
						{children}
					</Row>
				</Col>
			</Row >
		</Col >
	);
}
