import { createSlice } from "@reduxjs/toolkit";
import { editCallManager, getCallManager, getGlobalVariables } from "./globalVariablesActions";

const globalVariablesSlice = createSlice({
  name: "globalVariables",
  initialState: {
    loading: false,
    status: "",
    error: "",
    data: {},
    callManager: {}
  },
  reducers: {
    updateCallManager: (state, action) => {
      return {
        ...state,
        callManager: action.payload
      }
    },
    updateGlobalVariable: (state, action) => {
      return {
        ...state,
        data: action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGlobalVariables.pending, (state, action) => {
        state.status = "getGlobalVariables loading";
        state.loading = true;
      })
      .addCase(getGlobalVariables.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "getGlobalVariables succeeded";
        state.data = action.payload;
      })
      .addCase(getGlobalVariables.rejected, (state, action) => {
        state.status = "getGlobalVariables failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCallManager.pending, (state, action) => {
        state.status = "getCallManager loading";
        state.loading = true;
      })
      .addCase(getCallManager.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "getCallManager succeeded";
        state.callManager = action.payload;
      })
      .addCase(getCallManager.rejected, (state, action) => {
        state.status = "getCallManager failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(editCallManager.pending, (state, action) => {
        state.status = "editCallManager loading";
        state.loading = true;
      })
      .addCase(editCallManager.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "editCallManager succeeded";
      })
      .addCase(editCallManager.rejected, (state, action) => {
        state.status = "editCallManager failed";
        state.loading = false;
        state.error = action.error.message;
      })
  }
});

// Action creators are generated for each case reducer function
export const { updateCallManager, updateGlobalVariable } = globalVariablesSlice.actions;

export default globalVariablesSlice.reducer;
