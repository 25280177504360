import { createSlice } from "@reduxjs/toolkit";
import { getLotTransferOutByID, getLotTransferOutList, LotTransferOutAdd, LotTransferOutDelete,LotTransferOutEdit,getStockTransfer } from "./lotTransferOutAction";

const lotTransferOutSlice = createSlice({
    name: "lotTransferout",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        autoFillItems: {},
        filterParamsData: {},
        listCount: 0,
        lotTransferOutlist: [],
        lottransferoutitems:[],
        lotTransferOutParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        listItemCount: 0,
        pageParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        editLotTransferOutItems: {}
    },
    reducers: {

        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },
        changeParams: (state, action) => {
            return {
                ...state,
                pageParams: action.payload,
            };
        },
        SetLotTransferOutParams: (state, action) => {
            return {
                ...state,
                lotTransferOutParams: {
                    ...state.lotTransferOutParams,
                    ...action.payload
                },
            };
        },
        SetLotTransferOutItemParams: (state, action) => {
            return {
                ...state,
                pageParams: {
                    ...state.pageParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
        isDrawerVisible: (state, action) => {
            return {
                ...state,
                drawer: action.payload,
            };
        },
        setLotItemsList: (state, action) => {
            return {
                ...state,
                lottransferoutitems: [...state.lottransferoutitems, action.payload]
            }
        },
        removeItems: (state, action) => {
            return {
                ...state,
                lottransferoutitems: state.lottransferoutitems.map((e) => {
                    return e.id == action.payload
                        ? {
                            ...e,
                            dodelete: true,
                        }
                        : {
                            ...e,
                        };
                }),
            };
        },
        setEditLotTransferOutItems: (state, action) => {
            return {
                ...state,
                editLotTransferOutItems: action.payload,
            };
        },
        setEditLotTransferOutItemsList: (state, action) => {
            return {
                ...state,
                lottransferoutitems: state.lottransferoutitems.map((e) => {
                    if (e.id == action.payload.id) {
                        return {
                            ...e,
                            ...action.payload,
                        }
                    } else {
                        return e;
                    }
                })
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLotTransferOutList.pending, (state, action) => {
                state.status = "getLotTransferOutList loading";
                state.loading = true;
            })
            .addCase(getLotTransferOutList.fulfilled, (state, action) => {
                state.status = "getLotTransferOutList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.lotTransferOutParams?.page_size
                );

                state.lotTransferOutlist = response.results;
                state.listCount = response.count;
                state.lotTransferOutParams = {
                    ...state.lotTransferOutParams,
                    no_of_pages: noofpages,
                };
                state.lottransferoutitems = [];
            })
            .addCase(getLotTransferOutList.rejected, (state, action) => {
                state.status = "getLotTransferOutList failed";
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getLotTransferOutByID.pending, (state, action) => {
                state.status = "getLotTransferOutByID loading";
                state.loading = true;
            })
            .addCase(getLotTransferOutByID.fulfilled, (state, action) => {
                state.status = "getLotTransferOutByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
                state.lottransferoutitems = state.rowdata?.lottransferoutitems;
                state.listItemCount = action.payload.lottransferoutitems?.length;
            }
            )
            .addCase(getLotTransferOutByID.rejected, (state, action) => {
                state.status = "getLotTransferOutByID failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(LotTransferOutAdd.pending, (state, action) => {
                state.status = "LotTransferOutAdd loading";
                state.loading = true;
            })
            .addCase(LotTransferOutAdd.fulfilled, (state, action) => {
                state.status = "LotTransferOutAdd succeeded";
                state.loading = false
                state.model = action.payload.modal;
                state.message = action.payload.response;
                state.rowdata = {};
                state.lottransferoutitems = [];
            }
            )
            .addCase(LotTransferOutAdd.rejected, (state, action) => {
                state.status = "LotTransferOutAdd failed";
                state.loading = false;
                state.error = action.error.message;
                console.log("action.error.message", action.error.message);
            })
            .addCase(LotTransferOutEdit.pending, (state, action) => {
                state.status = "LotTransferOutEdit loading";
                state.loading = true;
            })
            .addCase(LotTransferOutEdit.fulfilled, (state, action) => {
                console.log(action.payload.response)
                state.status = "LotTransferOutEdit succeeded";
                state.loading = false;
                state.active = action.payload.active;
                state.lottransferoutitems = [];
            })
            .addCase(LotTransferOutEdit.rejected, (state, action) => {
                state.status = "LotTransferOutEdit failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(LotTransferOutDelete.pending, (state, action) => {
                state.status = "LotTransferOutDelete loading";
                state.loading = true;
            })
            .addCase(LotTransferOutDelete.fulfilled, (state, action) => {
                state.status = "LotTransferOutDelete succeeded";
                state.loading = false
            }
            )
            .addCase(LotTransferOutDelete.rejected, (state, action) => {
                state.status = "LotTransferOutDelete failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getStockTransfer.pending, (state, action) => {
                state.status = "getStockTransfer loading";
                state.loading = true;
            })
            .addCase(getStockTransfer.fulfilled, (state, action) => {
                state.status = "getStockTransfer succeeded";
                state.loading = false;
                var response = action.payload.response;
            })
            .addCase(getStockTransfer.rejected, (state, action) => {
                state.status = "getStockTransfer failed";
                state.loading = false;
                state.error = action.error.message;
            })
    }

});
export const {
    InputChangeValue,
    SetLotTransferOutParams,
    isModelVisible,
    isDrawerVisible,
    setLotItemsList,
    removeItems,
    changeParams,
    setEditLotTransferOutItems,
    setEditLotTransferOutItemsList,
    SetLotTransferOutItemParams
} = lotTransferOutSlice.actions;

export default lotTransferOutSlice.reducer;