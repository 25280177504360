const themeConfig = {
	version: "3.3",
	title: "DEC Infra",
	theme: "light", // light - dark
	contentWidth: "full", // full - boxed
	sidebarCollapsed: false,
	sidebarCollapseButton: true,
	layout: "VerticalLayout", // VerticalLayout - HorizontalLayout
	navigationFull: false,
	navigationBg: false,
	direction: "ltr", // ltr - rtl
};

export default themeConfig;
