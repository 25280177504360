import { createSlice } from "@reduxjs/toolkit";
import { getJattuByID, getJattuList, getJattuByCompany } from "./jattuAction";

const jattuSlice = createSlice({
    name: "jattu",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,        
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        listCount: 0,
        jattulist: [],
        jattuParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        jattuByCompany:[]
    },
    reducers: {
       
        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },   
        SetJattuParams: (state, action) => {
            return {
                ...state,
                jattuParams: {
                    ...state.jattuParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getJattuList.pending, (state, action) => {
                state.status = "getJattuList loading";
                state.loading = true;
            })
            .addCase(getJattuList.fulfilled, (state, action) => {
                console.log(action.payload.response)
                state.status = "getJattuList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.jattuParams?.page_size
                );

                state.jattulist = response.results;
                state.listCount = response.count;
                state.jattuParams = {
                    ...state.jattuParams,
                    no_of_pages: noofpages,
                };
            })
            .addCase(getJattuList.rejected, (state, action) => {
                state.status = "getJattuList failed";
                state.loading = false;
                state.error = action.error.message;
            })
            
            .addCase(getJattuByID.pending, (state, action) => {
                state.status = "getJattuByID loading";
                state.loading = true;
            })
            .addCase(getJattuByID.fulfilled, (state, action) => {
                state.status = "getJattuByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
            }
            )
            .addCase(getJattuByID.rejected, (state, action) => {
                state.status = "getJattuByID failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getJattuByCompany.pending, (state, action) => {
                state.status = "getJattuByCompany loading";
                state.loading = true;
            })
            .addCase(getJattuByCompany.fulfilled, (state, action) => {
                state.status = "getJattuByCompany succeeded";
                state.loading = false
                state.jattuByCompany = action.payload.results;
            }
            )
            .addCase(getJattuByCompany.rejected, (state, action) => {
                state.status = "getJattuByCompany failed";
                state.loading = false;
                state.error = action.error.message;
            })
           
    }

});
export const {
    InputChangeValue,
    SetJattuParams,
    isModelVisible
} = jattuSlice.actions;

export default jattuSlice.reducer;