import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { ImportUploadStatus, downloadExportData, exportdownloadfile, exportstatus, getExportModelsList, getImportModelsList, submitImportType2Data, uploadImportData, uploadImportType2Data } from "./importExportAction";

const importExportSlice = createSlice({
  name: "importExport",
  initialState: {
    loading: false,
    exportModelList: [],
    importModelList: [],
    importType: 0,
    exportData: {},
    importData: {},
    importTableColumns: [],
    importValidRowData: [],
    importTableRowData: [],
    submit_ImportData: {},
    currentStep: 0,
    model: false,
    managesParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    clearData: (state) => {
      return {
        ...state,
        loading: false,
        exportModelList: [],
        importModelList: [],
        importType: 0,
        exportData: {},
        importData: {},
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        exportData: {
          ...state.exportData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    nextStep: (state) => {
      state.currentStep = state.currentStep + 1;
    },
    prevStep: (state) => {
      state.currentStep = state.currentStep - 1;
    },
    finishStep: (state) => {
      return {
        ...state,
        exportData: {},
        importData: {},
        importTableColumns: [],
        importValidRowData: [],
        submit_ImportData: {},
        currentStep: 0,
      };
    },
    changeType: (state, action) => {
      return {
        ...state,
        importType: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getImportModelsList.pending, (state, action) => {
        state.loading = true;
        state.status = "getImportModelsList pending";
      })
      .addCase(getImportModelsList.fulfilled, (state, action) => {
        const { response } = action.payload
        state.status = "getImportModelsList succeeded";
        state.loading = false;
        state.importModelList = response;
      })
      .addCase(getImportModelsList.rejected, (state, action) => {
        state.status = "getImportModelsList failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getExportModelsList.pending, (state, action) => {
        state.loading = true;
        state.status = "getExportModelsList pending";
      })
      .addCase(getExportModelsList.fulfilled, (state, action) => {
        const { response } = action.payload
        state.status = "getExportModelsList succeeded";
        state.loading = false;
        state.exportModelList = response;
      })
      .addCase(getExportModelsList.rejected, (state, action) => {
        state.status = "getExportModelsList failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(downloadExportData.pending, (state, action) => {
        state.loading = true;
        state.status = "downloadExportData pending";
      })
      .addCase(downloadExportData.fulfilled, (state, action) => {
        state.status = "downloadExportData succeeded";
        state.loading = true;
      })
      .addCase(downloadExportData.rejected, (state, action) => {
        state.status = "downloadExportData failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(exportdownloadfile.pending, (state, action) => {
        state.loading = true;
        state.status = "exportdownloadfile pending";
      })
      .addCase(exportdownloadfile.fulfilled, (state, action) => {
        state.status = "exportdownloadfile succeeded";
        state.loading = false;
      })
      .addCase(exportdownloadfile.rejected, (state, action) => {
        state.status = "exportdownloadfile failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(exportstatus.pending, (state, action) => {
        state.loading = true;
        state.status = "exportstatus pending";
      })
      .addCase(exportstatus.fulfilled, (state, action) => {
        state.status = "exportstatus succeeded";
        state.loading = true;
      })
      .addCase(exportstatus.rejected, (state, action) => {
        state.status = "exportstatus failed";
        state.loading = false;
        state.error = action.error.message;
      })
      //uploadImportData
      .addCase(uploadImportData.pending, (state, action) => {
        state.loading = true;
        state.status = "uploadImportData pending";
      })
      .addCase(uploadImportData.fulfilled, (state, action) => {
        const { response } = action.payload;
        state.status = "uploadImportData succeeded";
        state.loading = true;
        state.request_id = response;
      })
      .addCase(uploadImportData.rejected, (state, action) => {
        state.status = "uploadImportData failed";
        state.loading = false;
        state.error = action.error.message;
      })
      //uploadImportData
      .addCase(ImportUploadStatus.pending, (state, action) => {
        state.loading = true;
        state.status = "ImportUploadStatus pending";
      })
      .addCase(ImportUploadStatus.fulfilled, (state, action) => {
        if (action.payload) {
          const { response } = action.payload;
          var columns = [];
          if (response != "Import is still running") {
            if (!response?.has_errors) {
              if (!response?.has_validation_errors) {
                if (response?.valid_rows?.length > 0) {
                  columns = Object.keys(response?.valid_rows[0]);
                }
              } else {
                if (response?.invalid_rows?.length > 0) {
                  columns = Object.keys(response?.invalid_rows[0]);
                }
              }
            }
            state.status = "ImportUploadStatus succeeded";
            state.loading = false;
            state.currentStep = 1;
            state.importType = 1;
            state.importData = response;
            state.importTableColumns = columns;
            state.importTableRowData = !response?.has_errors && response?.has_validation_errors
              ? response?.invalid_rows
              : response?.valid_rows;
          }
        }
      })
      .addCase(ImportUploadStatus.rejected, (state, action) => {
        state.status = "ImportUploadStatus failed";
        state.loading = false;
        state.error = action.error.message;
      })
      // uploadImportType2Data
      .addCase(uploadImportType2Data.pending, (state, action) => {
        state.loading = true;
        state.status = "uploadImportType2Data pending";
      })
      .addCase(uploadImportType2Data.fulfilled, (state, action) => {
        const { response } = action.payload;
        state.status = "uploadImportType2Data succeeded";
        state.loading = false;
        var columns = [];
        var columerformater = function (
          fields,
          columns = [],
          label = "",
          key = ""
        ) {
          for (let i = 0; i < fields.length; i++) {
            const field = fields[i];

            if (
              Object.keys(field).indexOf("is_serializer") !== -1 &&
              field.is_serializer
            ) {
              columns = columerformater(
                field.serializer_fields,
                columns,
                field.label + " ",
                field.key + "__"
              );
            } else {
              field.key = key + field.key;
              // field.label = label + field.label;
              columns.push(field);
            }
            if (i == fields.length - 1) {
              return columns;
            }
          }
        };

        columns = columerformater(action.payload.import_fields);
        columns.unshift(
          { key: "import_status", label: "Import Status" },
          { key: "errors", label: "errors" }
        );
        return {
          ...state,
          currentStep: 1,
          loading: false,
          importType: 2,
          importData: response,
          importTableColumns: columns,
          importTableRowData: response.data_list,
        };

      })
      .addCase(uploadImportType2Data.rejected, (state, action) => {
        state.status = "uploadImportType2Data failed";
        state.loading = false;
        state.error = action.error.message;
      })
      // submitImportType2Data
      .addCase(submitImportType2Data.pending, (state, action) => {
        state.loading = true;
        state.status = "submitImportType2Data pending";
      })
      .addCase(submitImportType2Data.fulfilled, (state, action) => {
        const { response } = action.payload;
        state.status = "submitImportType2Data succeeded";
        state.loading = false;
        state.currentStep = 2;
        state.submit_ImportData = response;

      })
      .addCase(submitImportType2Data.rejected, (state, action) => {
        state.status = "submitImportType2Data failed";
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  InputChangeValue,
  nextStep,
  prevStep,
  finishStep,
  changeType,
  clearData,
} = importExportSlice.actions;

export default importExportSlice.reducer;
