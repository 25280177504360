import { createSlice } from "@reduxjs/toolkit";
import { getCompanyList, getCompanyByID } from "./companyAction";

const companySlice = createSlice({
    name: "company",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,        
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        listCount: 0,
        companylist: [],
        companyParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        
    },
    reducers: {
       
        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },   
        SetCompanyParams: (state, action) => {
            return {
                ...state,
                companyParams: {
                    ...state.companyParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCompanyList.pending, (state, action) => {
                state.status = "getCompanyList loading";
                state.loading = true;
            })
            .addCase(getCompanyList.fulfilled, (state, action) => {
                state.status = "getCompanyList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.companyParams?.page_size
                );

                state.companylist = response.results;
                state.listCount = response.count;
                state.companyParams = {
                    ...state.companyParams,
                    no_of_pages: noofpages,
                };
            })
            .addCase(getCompanyList.rejected, (state, action) => {
                state.status = "getCompanyList failed";
                state.loading = false;
                state.error = action.error.message;
            })
            
            .addCase(getCompanyByID.pending, (state, action) => {
                state.status = "getCompanyByID loading";
                state.loading = true;
            })
            .addCase(getCompanyByID.fulfilled, (state, action) => {
                state.status = "getCompanyByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
            }
            )
            .addCase(getCompanyByID.rejected, (state, action) => {
                state.status = "getCompanyByID failed";
                state.loading = false;
                state.error = action.error.message;
            })
           
    }

});
export const {
    InputChangeValue,
    SetCompanyParams,
    isModelVisible
} = companySlice.actions;

export default companySlice.reducer;