import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseURL } from "../../../helpers/AxiosHelper";
import axios from "axios";

export const deleteUserAccount = createAsyncThunk(
	"/deleteUserAccount",
	async (payload) => {
		const { data, onSuccess, onFailure } = payload;
		try {
			const response = await axios.post(
				`${BaseURL}/system/customer/otp/request/`,
				data
			);
			if (response.status >= 200 && response.status < 300) {
				onSuccess(response.data);
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			onFailure(error.message);
			throw error.message;
		}
	}
);
export const deleteByOTP = createAsyncThunk("/deleteByOTP", async (payload) => {
	const { data, onSuccess, onFailure } = payload;
	try {
		const response = await axios.post(
			`${BaseURL}/system/customer/otp/verify/`,
			data
		);
		if (response.status >= 200 && response.status < 300) {
			onSuccess(response.data);
			return response.data;
		} else {
			throw new Error(response.data);
		}
	} catch (error) {
		onFailure(error.message);
		throw error.message;
	}
});
export const userdeactive = createAsyncThunk(
	"/userdeactive",
	async (payload) => {
		const { data, onSuccess, onFailure } = payload;
		try {
			const response = await axios.delete(
				`${BaseURL}/system/customer/deactivate/${data}`
			);
			if (response.status >= 200 && response.status < 300) {
				onSuccess(response.data);
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			onFailure(error.message);
			throw error.message;
		}
	}
);
