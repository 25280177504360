import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    getList,
    getParamsList,
    postEdit,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
    RiAlertLine,
    RiCheckboxCircleLine,
} from "react-icons/ri";
import Swal from "sweetalert2";


export const getGlobalVariables = createAsyncThunk(
    "/getGlobalVariables",
    async (payload, { dispatch }) => {
        try {
            const response = await getList("/system/globalvariables/customerapp/jsondata/");
            if (response.data) {
                return response.data
            } else {
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            return error.message;
        }
    }
);
export const editGlobalVariables = createAsyncThunk(
    "/editGlobalVariables",
    async (payload, { dispatch }) => {
        try {
            const response = await postEdit("/system/globalvariables/customerapp/jsondata/", payload);
            if (response.data) {
                dispatch(getGlobalVariables());
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Updated Successfully.!
                            </p>
                        </div>
                    ),
                });
                return response.data
            } else {
                Modal.error({
                    icon: (
                        <span className="remix-icon">
                            <RiAlertLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Sorry! Unable to Update. Please try again!
                            </p>
                        </div>
                    ),
                });
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            return error.message;
        }
    }
);
export const getCallManager = createAsyncThunk(
    "/getCallManager",
    async (payload) => {
        try {
            const response = await getList("/system/globalvariables/deliveryapp/callmanager/");
            if (response.data) {
                return response.data
            } else {
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            return error.message;
        }
    }
);
export const editCallManager = createAsyncThunk(
    "/callManager",
    async (payload, { dispatch }) => {
        try {
            const response = await postEdit("/system/globalvariables/deliveryapp/callmanager/", payload);
            if (response.status === 200) {
                dispatch(getCallManager())
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Updated Successfully.!
                            </p>
                        </div>
                    ),
                });
            } else {
                Modal.error({
                    icon: (
                        <span className="remix-icon">
                            <RiAlertLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Sorry! Unable to Update. Please try again!
                            </p>
                        </div>
                    ),
                });
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            return error.message;
        }
    }
);