import React, { useState, useEffect, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Layout, Row, Col } from "antd";

import Sidebar from "./components/menu/Sidebar";
import MenuHeader from "./components/header";
import MenuFooter from "./components/footer";
import ScrollTop from "./components/scroll-to-top";
import { getUserPermissions } from "../store/system/systemActions";

const { Content } = Layout;

function VerticalLayout(props) {
  const { children } = props;
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  // Redux
  const customise = useSelector((state) => state.customise);

  useEffect(() => {
    dispatch(getUserPermissions());
  }, []);

  return (
    <Layout className="hp-app-layout">
      <MenuHeader setVisible={setVisible} />
      <Layout className="hp-bg-color-dark-90">
        <Sidebar visible={visible} setVisible={setVisible} />
        <Content className="hp-content-main" >
          <Row justify="center">
            {
              customise.contentWidth == "full" && (
                <Col span={24}>
                  {children}
                </Col>
              )
            }

            {
              customise.contentWidth == "boxed" && (
                <Col xxl={20} xl={22} span={24}>
                  {children}
                </Col>
              )
            }
          </Row>
        </Content>

        {/* <MenuFooter /> */}
      </Layout>

      <ScrollTop />
    </Layout>
  );
}
export default VerticalLayout;
