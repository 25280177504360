import React from "react";
import { Row, Col, Table, Spin, Pagination, Card, Input } from "antd";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

function TableComponent(
  {
    showPagination = true,
    data,
    columns,
    loading,
    onPageChange,
    showTotal,
    count,
    page,
    pageSize,
    scroll,
    changeParams,
    // rowSelection = false,
  },
  props
) {
  const { Search } = Input;
  let location = useLocation();
  const dispatch = useDispatch();
  let pathname = ["/branch", "/hpshouse", "/professionalcategory"];

  return (
    // <Card>
      <Row>
        {pathname.find((e) => e == location.pathname) && (
          <Col
            span={24}
            xs={24}
            sm={24}
            md={12}
            className="hp-ecommerce-app-header-search  hp-mb-16"
          >
            <Search
              placeholder="Search for Name..."
              allowClear
              onChange={(e) => {
                if (e.target.value !== "") {
                  dispatch(
                    changeParams({
                      page_size: 10,
                      page: 1,
                      search: "",
                    })
                  );
                }
              }}
              onSearch={(value) => {
                dispatch(
                  changeParams({
                    page_size: 10,
                    page: 1,
                    search: value,
                  })
                );
              }}
            />
          </Col>
        )}

        <Col span={24}>
          <Table
            // rowSelection={rowSelection}
            bordered={false}
            tableLayout="auto"
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={scroll ? { x: 1500 } : false}
            size="middle"
            {...props}
          />
        </Col>
        {showPagination && (
          <Col span={24}>
            <Row justify="end">
              <Pagination
                size="small"
                current={page}
                pageSize={pageSize}
                total={count}
                showTotal={showTotal}
                showSizeChanger
                onChange={onPageChange}
              />
            </Row>
          </Col>
        )}
      </Row>
    // </Card>
  );
}

export default TableComponent;
