import { createSlice } from "@reduxjs/toolkit";
import { getOtherJattuActivityList, OtherJattuActivityAdd, getOtherJattuActivityByID, OtherJattuActivityEdit, OtherJattuActivityDelete, otherJattuActivitiesAttachments } from "./otherJattuActivityAction";

const otherJattuActivitySlice = createSlice({
    name: "otherjattuactivity",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        // autoFillItems: {},
        filterParamsData: {},
        listCount: 0,
        otherJattuActivitylist: [],
        otherJattuActivityItems: [],
        otherJattuActivityParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        showCamera: false,
        listItemCount: 0,
        pageParams: {
            start_date: "",
            end_date: "",
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
        },
        uploadImage: {
            file: {},
            imageUrl: "",
            loading: false,
        },
        otherJattuActivityImages: [],
        editOtherJattuActivities: {}
    },
    reducers: {

        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },
        SetOtherJattuActivityParams: (state, action) => {
            return {
                ...state,
                otherJattuActivityParams: {
                    ...state.otherJattuActivityParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
        isDrawerVisible: (state, action) => {
            return {
                ...state,
                drawer: action.payload,
            };
        },
        setAutoFillItems: (state, action) => {
            return {
                ...state,
                ...action.payload,
            }
        },
        changeParams: (state, action) => {
            return {
                ...state,
                pageParams: action.payload,
            };
        },
        clearuploadImage: (state, action) => {
            return {
                ...state,
                uploadImage: {
                    file: {},
                    imageUrl: "",
                    loading: false,
                },
            };
        },
        setUploadImage: (state, action) => {
            return {
                ...state,
                uploadImage: {
                    file: action.payload.file,
                    imageUrl: action.payload.imageUrl,
                    loading: action.payload.loading
                },
            };
        },
        setShowCamera: (state, action) => {
            return {
                ...state,
                showCamera: action.payload,
            }
        },
        setOtherJattuActivityImages: (state, action) => {
            return {
                ...state,
                otherJattuActivityImages: [...state.otherJattuActivityImages, action.payload]
            }
        },
        clearImages: (state, action) => {
            state.otherJattuActivityImages = [];
        },
        removeImages: (state, action) => {
            return {
                ...state,
                otherJattuActivityImages: state.otherJattuActivityImages?.filter((image) => image?.id != action.payload)
            };
        },
        removeNullValuesFromImages: (state,action)=>{
            return {
                ...state,
                otherJattuActivityImages: state.otherJattuActivityImages?.filter(item => item !== null)
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOtherJattuActivityList.pending, (state, action) => {
                state.status = "getOtherJattuActivityList loading";
                state.loading = true;
            })
            .addCase(getOtherJattuActivityList.fulfilled, (state, action) => {
                state.status = "getOtherJattuActivityList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.otherJattuActivityParams?.page_size
                );

                state.otherJattuActivitylist = response.results;
                state.listCount = response.count;
                state.otherJattuActivityParams = {
                    ...state.otherJattuActivityParams,
                    no_of_pages: noofpages,
                };
                state.otherJattuActivityImages = [];
                state.rowdata.trucksheet_items = [];
                state.otherJattuActivityItems = [];
            })
            .addCase(getOtherJattuActivityList.rejected, (state, action) => {
                state.status = "getOtherJattuActivityList failed";
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getOtherJattuActivityByID.pending, (state, action) => {
                state.status = "getOtherJattuActivityByID loading";
                state.loading = true;
            })
            .addCase(getOtherJattuActivityByID.fulfilled, (state, action) => {
                // state.otherJattuActivityImages = [];
                state.status = "getOtherJattuActivityByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
                state.otherJattuActivityImages = [
                    action.payload.activity_image_1,
                    action.payload.activity_image_2,
                    action.payload.activity_image_3,
                    action.payload.activity_image_4,
                ]
                state.listItemCount = action.payload.otherjattuactivityitems?.length;
            }
            )
            .addCase(getOtherJattuActivityByID.rejected, (state, action) => {
                state.status = "getOtherJattuActivityByID failed";
                state.loading = false;
                state.error = action.error.message;

            })
            .addCase(OtherJattuActivityAdd.pending, (state, action) => {
                state.status = "OtherJattuActivityAdd loading";
                state.loading = true;
            })
            .addCase(OtherJattuActivityAdd.fulfilled, (state, action) => {
                state.status = "OtherJattuActivityAdd succeeded";
                state.loading = false
                state.model = action.payload.modal;
                state.message = action.payload.response;
                state.rowdata = {};
                state.otherJattuActivityItems = [];
            }
            )
            .addCase(OtherJattuActivityAdd.rejected, (state, action) => {
                state.status = "OtherJattuActivityAdd failed";
                state.loading = false;
                state.error = action.error.message;
                console.log("action.error.message", action.error.message);
            })
            .addCase(OtherJattuActivityEdit.pending, (state, action) => {
                state.status = "OtherJattuActivityEdit loading";
                state.loading = true;
            })
            .addCase(OtherJattuActivityEdit.fulfilled, (state, action) => {
                console.log(action.payload.response)
                state.status = "OtherJattuActivityEdit succeeded";
                state.loading = false;
                state.active = action.payload.active;
                state.otherJattuActivityItems = [];
            })
            .addCase(OtherJattuActivityEdit.rejected, (state, action) => {
                state.status = "OtherJattuActivityEdit failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(OtherJattuActivityDelete.pending, (state, action) => {
                state.status = "OtherJattuActivityDelete loading";
                state.loading = true;
            })
            .addCase(OtherJattuActivityDelete.fulfilled, (state, action) => {
                state.status = "OtherJattuActivityDelete succeeded";
                state.loading = false
            }
            )
            .addCase(OtherJattuActivityDelete.rejected, (state, action) => {
                state.status = "OtherJattuActivityDelete failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(otherJattuActivitiesAttachments.pending, (state, action) => {
                state.status = "loading";
                state.loading = true;
            })
            .addCase(otherJattuActivitiesAttachments.fulfilled, (state, action) => {
                const { response } = action.payload;
                state.status = "succeeded";
                state.loading = false;
                state.otherJattuActivityImages = [...state.otherJattuActivityImages, response];
            })
            .addCase(otherJattuActivitiesAttachments.rejected, (state, action) => {
                state.status = "failed";
                state.loading = false;
                state.error = action.error.message;
            })
    }

});
export const {
    InputChangeValue,
    SetOtherJattuActivityParams,
    isModelVisible,
    isDrawerVisible,
    setAutoFillItems,
    changeParams,
    setStockAdjustmentItemsList,
    clearuploadImage,
    setUploadImage,
    setShowCamera,
    removeImages,
    removeNullValuesFromImages,
    setOtherJattuActivityImages
} = otherJattuActivitySlice.actions;

export default otherJattuActivitySlice.reducer;