import { createSlice } from "@reduxjs/toolkit";
import {
  getUserPermissions, getMiniCity, getMiniStates, getMiniLocation,
  getMiniJattu,
  getMiniJattuActivity,
  getMiniVehicleInward,
  getMinicompany,
  getMiniLot,
  getMiniItem,
  getMiniWarehouse,
  getMiniTrucksheet,
  getMiniDeliveryorder
} from "./miniActions";
const miniSlice = createSlice({
  name: "mini",
  initialState: {
    userAccessList: [],
    miniStatesList: [],
    miniStateLoading: false,
    miniCityList: [],
    miniCityLoading: false,
    miniJattuList: [],
    miniJattuLoading: false,
    miniJattuActivityList: [],
    miniJattuActivityLoading: false,
    miniVehicleInwardList: [],
    miniVehicleInwardLoading: false,
    miniUserList: [],
    miniAreaList: [],
    miniAreaLoading: false,
    miniLocationList: [],
    miniLocationLoading: false,
    miniCompanyList: [],
    miniCompanyLoading: false,
    miniLotList: [],
    miniLotLoading: false,
    miniItemList: [],
    miniItemLoading: false,
    miniWarehouseList: [],
    miniWarehouseLoading: false,
    minitrucksheetList: [],
    minitrucksheetLoading: false,
    miniDeliveryorderList: [],
    miniDeliveryorderLoading: false,
    miniStatesParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniCityParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniAreaParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniCompanyParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniLotParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniItemParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniWarehouseParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniTrucksheetParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniDeliveryorderParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniVehicleInwardParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniJattuParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniJattuActivityParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniLocationParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniZoneParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniUserParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
  },
  reducers: {
    setMiniStatesParams: (state, action) => {
      return {
        ...state,
        miniStatesParams: action.payload,
      };
    },
    setMiniZoneParams: (state, action) => {
      return {
        ...state,
        miniZoneParams: action.payload,
      };
    },
    setMiniAreaParams: (state, action) => {
      return {
        ...state,
        miniAreaParams: action.payload,
      };
    },
    setMiniLocationParams: (state, action) => {
      return {
        ...state,
        miniLocationParams: action.payload,
      };
    },
    getMiniStateClearData: (state) => {
      return {
        ...state,
        miniStatesList: [],
      };
    },
    setMiniCityParams: (state, action) => {
      return {
        ...state,
        miniCityParams: action.payload,
      };
    },
    getMiniCityClearData: (state) => {
      return {
        ...state,
        miniCityList: [],
      };
    },
    setMiniUserParams: (state, action) => {
      return {
        ...state,
        miniUserParams: action.payload,
      };
    },
    setMiniCompanyParams: (state, action) => {
      return {
        ...state,
        miniCompanyParams: action.payload
      }
    },
    setMiniLotParams: (state, action) => {
      return {
        ...state,
        miniLotParams: action.payload
      }
    },
    setMiniItemParams: (state, action) => {
      return {
        ...state,
        miniItemParams: action.payload
      }
    },
    setMiniWarehouseParams: (state, action) => {
      return {
        ...state,
        miniWarehouseParams: action.payload
      }
    },
    setMiniTrucksheetParams: (state, action) => {
      return {
        ...state,
        miniTrucksheetParams: action.payload
      }
    },
    setMiniDeliveryorderParams: (state, action) => {
      return {
        ...state,
        miniDeliveryorderParams: action.payload
      }
    },
    setMiniVehicleInwardParams: (state, action) => {
      return {
        ...state,
        miniVehicleInwardParams: action.payload
      }
    },
    setMiniJattuParams: (state, action) => {
      return {
        ...state,
        miniJattuParams: action.payload
      }
    },
    setMiniJattuActivityParams: (state, action) => {
      return {
        ...state,
        miniJattuActivityParams: action.payload
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserPermissions.pending, (state, action) => {
        state.status = "getUserPermissions loading";
        state.loading = true;
      })
      .addCase(getUserPermissions.fulfilled, (state, action) => {
        state.status = "getUserPermissions succeeded";
        state.loading = false;
        state.userAccessList = action.payload.response;
      })
      .addCase(getUserPermissions.rejected, (state, action) => {
        state.status = "getUserPermissions failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniStates.pending, (state, action) => {
        state.status = "getMiniStates loading";
        state.miniStateLoading = true;
      })
      .addCase(getMiniStates.fulfilled, (state, action) => {
        state.status = "getMiniStates succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else {
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniStatesList, ...response.results];
        }
        // }

        var noofpages = Math.ceil(
          response.count / state.miniStatesParams?.page_size
        );
        state.miniStatesList = list;
        state.miniStateLoading = false;
        state.miniStatesParams = {
          ...state.miniStatesParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getMiniStates.rejected, (state, action) => {
        state.status = "getMiniStates failed";
        state.miniStateLoading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniCity.pending, (state, action) => {
        state.status = "getMiniCity loading";
        // if (state.miniCityParams?.page == 1) {
        //   state.miniCityLoading = true;
        //   state.miniCityList = [];
        // } else {
        state.miniCityLoading = true;
        // }
      })
      .addCase(getMiniCity.fulfilled, (state, action) => {
        state.status = "getMiniCity succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniCityList, ...response.results];
        }

        var noofpages = Math.ceil(
          response.count / state.miniCityParams?.page_size
        );
        state.miniCityList = list;
        state.miniCityLoading = false;
        state.miniCityParams = {
          ...state.miniCityParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getMiniCity.rejected, (state, action) => {
        state.status = "getMiniCity failed";
        state.miniCityLoading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniLocation.pending, (state, action) => {
        state.status = "getMiniLocation loading";
        // if (state.miniLocationParams?.page == 1) {
        //   state.miniLocationLoading = true;
        //   state.miniLocationList = [];
        // } else {
        state.miniLocationLoading = true;
        // }
      })
      .addCase(getMiniLocation.fulfilled, (state, action) => {
        state.status = "getMiniLocation succeeded";

        console.log(action.payload)
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          console.log(response.results)
          list = response.results;
        } else {
          list = [...state.miniLocationList, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniLocationParams?.page_size
        );
        state.miniLocationList = list;
        state.miniLocationLoading = false;
        state.miniLocationParams = {
          ...state.miniLocationParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getMiniLocation.rejected, (state, action) => {
        state.status = "getMiniLocation failed";
        state.miniLocationLoading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniJattu.pending, (state, action) => {
        state.status = "getMiniJattu loading";
        state.miniJattuLoading = true;
      })
      .addCase(getMiniJattu.fulfilled, (state, action) => {
        state.status = "getMiniJattu succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        if (params?.page == 1) {
          console.log(response.results)
          list = response.results;
        } else {
          list = [...state.miniJattuList, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniJattuParams?.page_size
        );
        state.miniJattuList = list;
        state.miniJattuLoading = false;
        state.miniJattuParams = {
          ...state.miniJattuParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getMiniJattu.rejected, (state, action) => {
        state.status = "getMiniJattu failed";
        state.miniJattuLoading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniJattuActivity.pending, (state, action) => {
        state.status = "getMiniJattuActivity loading";
        state.miniJattuActivityLoading = true;
      })
      .addCase(getMiniJattuActivity.fulfilled, (state, action) => {
        state.status = "getMiniJattuActivity succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniJattuActivityList, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniJattuActivityParams?.page_size
        );
        state.miniJattuActivityList = list;
        state.miniJattuActivityLoading = false;
        state.miniJattuActivityParams = {
          ...state.miniJattuActivityParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getMiniJattuActivity.rejected, (state, action) => {
        state.status = "getMiniJattuActivity failed";
        state.miniJattuActivityLoading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniVehicleInward.pending, (state, action) => {
        state.status = "getMiniVehicleInward loading";
        state.miniVehicleInwardLoading = true;
      })
      .addCase(getMiniVehicleInward.fulfilled, (state, action) => {
        state.status = "getMiniVehicleInward succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniVehicleInwardList, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniVehicleInwardParams?.page_size
        );
        state.miniVehicleInwardList = list;
        state.miniVehicleInwardLoading = false;
        state.miniVehicleInwardParams = {
          ...state.miniVehicleInwardParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getMiniVehicleInward.rejected, (state, action) => {
        state.status = "getMiniVehicleInward failed";
        state.miniVehicleInwardLoading = false;
        state.error = action.error.message;
      })
      .addCase(getMinicompany.pending, (state, action) => {
        state.status = "getMinicompany loading";
        state.miniCompanyLoading = true;
      })
      .addCase(getMinicompany.fulfilled, (state, action) => {
        state.status = "getMinicompany succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniCompanyList, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniCompanyParams?.page_size
        );
        state.miniCompanyList = list;
        state.miniCompanyLoading = false;
        state.miniCompanyParams = {
          ...state.miniCompanyParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getMinicompany.rejected, (state, action) => {
        state.status = "getMinicompany failed";
        state.miniCompanyLoading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniLot.pending, (state, action) => {
        state.status = "getMiniLot loading";
        state.miniLotLoading = true;
      })
      .addCase(getMiniLot.fulfilled, (state, action) => {
        state.status = "getMiniLot succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniLotList, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniLotParams?.page_size
        );
        state.miniLotList = list;
        state.miniLotLoading = false;
        state.miniLotParams = {
          ...state.miniLotParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getMiniLot.rejected, (state, action) => {
        state.status = "getMiniLot failed";
        state.miniLotLoading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniItem.pending, (state, action) => {
        state.status = "getMiniItem loading";
        state.miniItemLoading = true;
      })
      .addCase(getMiniItem.fulfilled, (state, action) => {
        state.status = "getMiniItem succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniItemList, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniItemParams?.page_size
        );
        state.miniItemList = list;
        state.miniItemLoading = false;
        state.miniItemParams = {
          ...state.miniItemParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getMiniItem.rejected, (state, action) => {
        state.status = "getMiniItem failed";
        state.miniItemLoading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniWarehouse.pending, (state, action) => {
        state.status = "getMiniWarehouse loading";
        state.miniWarehouseLoading = true;
      })
      .addCase(getMiniWarehouse.fulfilled, (state, action) => {
        state.status = "getMiniWarehouse succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniWarehouseList, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniWarehouseParams?.page_size
        );
        state.miniWarehouseList = list;
        state.miniWarehouseLoading = false;
        state.miniWarehouseParams = {
          ...state.miniWarehouseParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getMiniWarehouse.rejected, (state, action) => {
        state.status = "getMiniWarehouse failed";
        state.miniWarehouseLoading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniTrucksheet.pending, (state, action) => {
        state.status = "getMiniTrucksheet loading";
        state.minitrucksheetLoading = true;
      })
      .addCase(getMiniTrucksheet.fulfilled, (state, action) => {
        state.status = "getMiniTrucksheet succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.minitrucksheetList, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniTrucksheetParams?.page_size
        );
        state.minitrucksheetList = list;
        state.minitrucksheetLoading = false;
        state.miniTrucksheetParams = {
          ...state.miniTrucksheetParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getMiniTrucksheet.rejected, (state, action) => {
        state.status = "getMiniTrucksheet failed";
        state.minitrucksheetLoading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniDeliveryorder.pending, (state, action) => {
        state.status = "getMiniDeliveryorder loading";
        state.miniDeliveryorderLoading = true;
      })
      .addCase(getMiniDeliveryorder.fulfilled, (state, action) => {
        state.status = "getMiniDeliveryorder succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniDeliveryorderList, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniDeliveryorderParams?.page_size
        );
        state.miniDeliveryorderList = list;
        state.miniDeliveryorderLoading = false;
        state.miniDeliveryorderParams = {
          ...state.miniDeliveryorderParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getMiniDeliveryorder.rejected, (state, action) => {
        state.status = "getMiniDeliveryorder failed";
        state.miniDeliveryorderLoading = false;
        state.error = action.error.message;
      })
  }
});

// Action creators are generated for each case reducer function
export const {
  setMiniStatesParams,
  setMiniZoneParams,
  setMiniAreaParams,
  setMiniLocationParams,
  getMiniStateClearData,
  setMiniCityParams,
  setMiniUserParams,
  setMiniJattuActivityParams,
  setMiniJattuParams,
  setMiniVehicleInwardParams,
  setMiniDeliveryorderParams,
  setMiniCompanyParams,
  setMiniItemParams,
  setMiniLotParams,
  setMiniWarehouseParams,
  setMiniTrucksheetParams
} = miniSlice.actions;

export default miniSlice.reducer;
